export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_LOCATIONS_OF_COMPANY = 'LOAD_LOCATIONS_OF_COMPANY';
export const LOAD_LOCATION = 'LOAD_LOCATION';
export const LOAD_FURNITURE = 'LOAD_FURNITURE';
export const LOAD_HOTSPOTS = 'LOAD_HOTSPOTS';
export const LOAD_LOCATION_HOTSPOTS = 'LOAD_LOCATION_HOTSPOTS';
export const LOAD_TILE_STATS = 'LOAD_TILE_STATS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const RESET_LOCATION = 'RESET_LOCATION';
export const RESET_COMPANY_LOCATIONS = 'RESET_COMPANY_LOCATIONS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CREATE_TOAST = 'CREATE_TOAST';
export const DELETE_TOAST = 'DELETE_TOAST';
export const SET_SEARCH_NAME = 'SET_SEARCH_NAME';
export const SET_HIDE_EMPTY_FOLDERS = 'SET_HIDE_EMPTY_FOLDERS';
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
