import * as actionTypes from '../actions/types';

export default function HideEmptyFoldersReducer(state = true, action) {
  switch (action.type) {
    case actionTypes.SET_HIDE_EMPTY_FOLDERS: {
      return action.checked;
    }
    default:
      return state;
  }
}
