import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Collapse } from 'react-bootstrap';
import { Field, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import InfoTooltipIcon from '../InfoTooltipIcon';
import renderInlineField from './InlineField';
import { PointTypes, PointNames } from '../../../constants';

function PointFieldsDefault({
  fields,
  show,
  dispatch,
  LOCATION_POINT_SHORT_INFO,
}) {
  const toggleCheckboxes = e => {
    if (LOCATION_POINT_SHORT_INFO.some(p => p.noImage)) {
      dispatch(
        change(
          'update_location',
          'LOCATION_POINT_SHORT_INFO',
          LOCATION_POINT_SHORT_INFO.map(f => ({ ...f, noImage: false }))
        )
      );
    } else {
      dispatch(
        change(
          'update_location',
          'LOCATION_POINT_SHORT_INFO',
          LOCATION_POINT_SHORT_INFO.map(f => ({ ...f, noImage: true }))
        )
      );
    }
  };

  const clearPortal = idx => {
    dispatch(
      change('update_location', `LOCATION_POINT_SHORT_INFO[${idx}]`, {
        ...LOCATION_POINT_SHORT_INFO[idx],
        portalId: '',
        portalPoint: '',
      })
    );
  };

  const clearHotspotId = idx => {
    dispatch(
      change('update_location', `LOCATION_POINT_SHORT_INFO[${idx}]`, {
        ...LOCATION_POINT_SHORT_INFO[idx],
        hotspotId: '',
      })
    );
  };

  return (
    <Collapse in={show}>
      <div
        className="mt-4 mb-4 pt-4 pb-3"
        style={{ backgroundColor: '#f4f4f4' }}
      >
        <Col>
          <Row className="pb-2">
            <Col className="pl-4" sm={1}>
              Point
            </Col>
            <Col className="pl-4" sm={2}>
              Title
            </Col>
            <Col className="pl-4" sm={1}>
              X
            </Col>
            <Col className="pl-4" sm={1}>
              Y
            </Col>
            <Col className="pl-4" sm={1}>
              Floor
            </Col>
            <Col className="pl-4" sm={1}>
              camera target{' '}
              <InfoTooltipIcon tooltip="Number of the point to which the camera will be pointed when location uploaded (an optional field)" />
            </Col>
            <Col className="pl-4" sm={1}>
              Badge
            </Col>
            <Col className="pl-4" sm={2}>
              Transition{' '}
              <InfoTooltipIcon
                tooltip={
                  <div>
                    <div className="pb-1">
                      Opening another location after moving to this point.
                    </div>
                    <div className="text-left">
                      - ID: location to transition
                    </div>
                    <div className="text-left">
                      - #: point in a new location
                    </div>
                  </div>
                }
              />
            </Col>
            <Col className="pl-4" sm={1}>
              Hotspot view
            </Col>
            <Col className="pl-4" sm={1} onClick={toggleCheckboxes}>
              ❌
            </Col>
          </Row>
        </Col>

        {fields.map((point, index) => {
          const info = fields.get(index);

          return (
            <Col
              key={index}
              className="pt-2 pb-2"
              style={{
                backgroundColor: info && info.noImage ? '#ffdee2' : '#f4f4f4',
              }}
            >
              <Form.Group as={Row} className="m-0">
                <Form.Label column sm={1}>
                  <b>{`${index + 1}`}</b>
                </Form.Label>
                <Field
                  name={`${point}.name`}
                  type="text"
                  label={`Point #${index + 1}`}
                  component={renderInlineField}
                  span={2}
                />
                <Field
                  name={`${point}.x`}
                  type="text"
                  placeholder="X"
                  component={renderInlineField}
                  span={1}
                />
                <Field
                  name={`${point}.y`}
                  type="text"
                  placeholder="Y"
                  component={renderInlineField}
                  span={1}
                />
                <Field
                  name={`${point}.floor`}
                  type="text"
                  placeholder="Floor"
                  component={renderInlineField}
                  span={1}
                />
                <Field
                  name={`${point}.target`}
                  type="text"
                  placeholder="Target"
                  component={renderInlineField}
                  span={1}
                />
                <Field
                  name={`${point}.icon`}
                  component={renderInlineField}
                  span={1}
                  as="select"
                >
                  <option value="">No</option>
                  <option value={PointTypes.HELI}>
                    {PointNames[PointTypes.HELI]}
                  </option>
                  <option value={PointTypes.GYM}>
                    {PointNames[PointTypes.GYM]}
                  </option>
                  <option value={PointTypes.HOME}>
                    {PointNames[PointTypes.HOME]}
                  </option>
                  <option value={PointTypes.POOL}>
                    {PointNames[PointTypes.POOL]}
                  </option>
                  <option value={PointTypes.SOFA}>
                    {PointNames[PointTypes.SOFA]}
                  </option>
                  <option value={PointTypes.ARROW_UP}>
                    {PointNames[PointTypes.ARROW_UP]}
                  </option>
                  <option value={PointTypes.ARROW_LEFT}>
                    {PointNames[PointTypes.ARROW_LEFT]}
                  </option>
                  <option value={PointTypes.ARROW_RIGHT}>
                    {PointNames[PointTypes.ARROW_RIGHT]}
                  </option>
                </Field>
                <Field
                  name={`${point}.portalId`}
                  type="text"
                  placeholder="ID"
                  component={renderInlineField}
                  span={1}
                  onChange={() => clearHotspotId(index)}
                />
                <Field
                  name={`${point}.portalPoint`}
                  type="text"
                  placeholder="#"
                  component={renderInlineField}
                  span={1}
                  onChange={() => clearHotspotId(index)}
                />
                <Field
                  name={`${point}.hotspotId`}
                  type="number"
                  placeholder="0"
                  component={renderInlineField}
                  span={1}
                  onChange={() => clearPortal(index)}
                />
                <Field
                  name={`${point}.noImage`}
                  type="checkbox"
                  placeholder="234"
                  component={renderInlineField}
                  span={1}
                />
              </Form.Group>
            </Col>
          );
        })}
      </div>
    </Collapse>
  );
}

const selector = formValueSelector('update_location');

PointFieldsDefault.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => {
  return {
    LOCATION_POINT_SHORT_INFO: selector(state, 'LOCATION_POINT_SHORT_INFO'),
  };
})(PointFieldsDefault);
