import React from 'react';

function EmptyPlus({ onClick }) {
  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <img
        src={`${process.env.PUBLIC_URL}/img/plus.svg`}
        alt="plus"
        style={{ height: '50vh', width: '50vh' }}
        onClick={onClick}
        className="plus-icon"
      />
    </div>
  );
}

export default EmptyPlus;
