import * as actionTypes from '../actions/types';

const initialState = {
  show: false,
};

export default function ContextMenuReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_CONTEXT_MENU: {
      return { ...action.payload, show: true };
    }
    case actionTypes.HIDE_CONTEXT_MENU: {
      return initialState;
    }
    default:
      return state;
  }
}
