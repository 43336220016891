import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { hideContextMenu } from '../../../../redux/actions';
import MoveMenu from './MoveMenu';
import DeleteIcon from 'mdi-react/DeleteIcon';
import RenameIcon from 'mdi-react/RenameIcon';
import FolderMoveIcon from 'mdi-react/FolderMoveIcon';

export const MOVE_OBJECT_TYPE = Object.freeze({
  LOCATION: 'LOCATION',
  MULTILOCATION: 'MULTILOCATION',
});

const ContextOption = ({ label, onClick, children }) => {
  return (
    <div className="d-flex align-items-center context-option" onClick={onClick}>
      {children}
      <div className="text">{label}</div>
    </div>
  );
};

function ContextMenu({ contextMenu, hideContextMenu }) {
  const {
    show,
    offsetY,
    offsetX,
    deleteHandler,
    renameHandler,
    canShowMove,
    moveContext,
  } = contextMenu;
  const [showMove, setShowMove] = useState(false);
  const menuRef = useRef(null);
  const [offsetFix, setOffsetFix] = useState({ x: 0, y: 0 });

  if (!show && showMove) {
    setShowMove(prev => false);
  }

  const moveHandler = () => {
    setShowMove(prev => !prev);
  };

  const closeHandler = () => {
    hideContextMenu();
  };

  useLayoutEffect(() => {
    if (!menuRef.current) {
      return;
    }

    const width = menuRef.current.offsetWidth + 20;
    const height = menuRef.current.offsetHeight + 20;

    const { scrollWidth, scrollHeight } = document.body;

    const offsetFix = { x: 0, y: 0 };

    if (offsetX + width > scrollWidth) {
      const maxX = scrollWidth - width;
      offsetFix.x = maxX - offsetX;
    }

    if (offsetY + height > scrollHeight) {
      const maxY = scrollHeight - height;
      offsetFix.y = maxY - offsetY;
    }

    setOffsetFix(offsetFix);
  }, [menuRef.current, offsetX, offsetY]);

  return (
    <>
      {show && (
        <div
          className="context-menu"
          ref={menuRef}
          style={{ left: offsetX + offsetFix.x, top: offsetY + offsetFix.y }}
        >
          {/* <CloseIcon onClick={closeHandler} /> */}
          <div className="container">
            {showMove ? (
              <MoveMenu context={moveContext} />
            ) : (
              <>
                <ContextOption label="Delete" onClick={deleteHandler}>
                  <DeleteIcon />
                </ContextOption>
                <ContextOption label="Rename" onClick={renameHandler}>
                  <RenameIcon />
                </ContextOption>
                {canShowMove && (
                  <ContextOption label="Move" onClick={moveHandler}>
                    <FolderMoveIcon />
                  </ContextOption>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  contextMenu: state.contextMenu,
});

const mapDispatchToProps = {
  hideContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
