export default function scrollToInvalid(errors) {
  if (errors) {
    const keys = Object.keys(errors);
    const offsets = keys
      .map(key => {
        const element = document.getElementsByName(key)[0];

        return element && element.getBoundingClientRect().top;
      })
      .filter(Number);
    const topOffset = Math.min(...offsets);
    window.scrollTo(0, window.pageYOffset + (topOffset - 100));
  }
}
