import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import HotspotSettings from './HotspotSettings';
import { HotspotTypes } from '../../../constants';

const HotspotModal = ({
  show,
  edit,
  hotspot = {},
  uploading,
  onSubmit,
  onClose,
}) => {
  const initialGaleryItems =
    hotspot && hotspot.items ? hotspot.items.map(item => item.id) : [];

  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hotspot</Modal.Title>
        </Modal.Header>
        <HotspotSettings
          onSubmit={onSubmit}
          // TODO: remove 'items'
          initialValues={{
            type: HotspotTypes.IMAGE,
            ...hotspot,
            items: initialGaleryItems,
          }}
          uploading={uploading}
          edit={edit}
        />
      </Modal>
    </div>
  );
};

HotspotModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HotspotModal;
