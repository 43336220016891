import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormLabel, Button, Form } from 'react-bootstrap';
import { reduxForm, change, Field } from 'redux-form';

import FileInput from '../../../shared/components/form/FileInput';
import parseModel from '../../../shared/utils/parseModel';
import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import InfoTooltipIcon from '../../../shared/components/InfoTooltipIcon';
import bakeTextures, { buildUrl } from '../../../shared/utils/bakeTextures';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ZipBuilder from '../../../shared/utils/ZipBuilder';
import API, { existsOnServer } from '../../../shared/utils/API';
import JSZip from 'jszip';
import parseModelGenerator from '../../../shared/utils/parseModelGenerator';
import VanishIcon from 'mdi-react/VanishIcon';
import styled from 'styled-components';
import { validateArchiveMeshGenerate } from '../../../shared/utils/validate';
import * as zipjs from '@zip.js/zip.js';
import { log } from 'three';
import { GreyPage } from './TileInput';
import renderField from '../../../shared/components/form/Field';
export const ZipFileInputStorage = {
  newFiles: [],
  // dollhouseTextures: []
};
const RotatingSpan = styled.span`
  transform: rotate(0deg);
  animation: loading 2s linear forwards infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ZipFileInput = ({
  onSubmit,
  onModelParse,
  uploading,
  uploadingText,
  uploaded,
  hasLocation,
  uploadingProgress,
  locationDescription,
  resetUploadModel,
  photoTourChecked,
  dispatch,
  locationId,
  createToast,
  setUploading,
  meshGenerationQueuePos,
}) => {
  const initialConfigState = {
    downsampling: 1,
    scan_depth_range: 11,
    outliner_removal: {
      neighbors: 20,
      ratio: 5,
    },
    voxel_size: 0.015,
    convert_depth: 12,
    quantile: 0.02,
    is_location_open: false,
    remove_small_meshes: false,
    texture_resolution: 8192,
    simplify_target_tris: 200000,
  };

  const [meshOptions, setMeshOptions] = useState(initialConfigState);
  const [submitButtonLabel, setSubmitButtonLabel] = useState('Upload');
  const [bakeButtonLabel, setBakeButtonLabel] = useState('Bake textures');
  const [bakeButtonDisabled, setBakeButtonDisabled] = useState(false);
  const [bakeDepthThreshold, setBakeDepthThreshold] = useState(1);
  const [bakeHalfDepth, setBakeHalfDepth] = useState(true);
  const [bakeQuality, setBakeQuality] = useState(0.25);
  const [showBakeSettings, setShowBakeSettings] = useState(false);
  const [updateMesh, setUpdateMesh] = useState(false);
  const [updatePano, setUpdatePano] = useState(false);
  const [locationArchiveSelected, setLocationArchiveSelected] = useState(false);
  const [generateMesh, setGenerateMesh] = useState(false);
  const [saveGenMesh, setSaveGenMesh] = useState(false);
  const instantPano = locationDescription.INSTANT_PANO_ONLY || false;

  if (!ZipFileInputStorage.newFiles) {
    ZipFileInputStorage.newFiles = [];
  }

  const alert = !uploaded ? (
    <AlertTooltipIcon tooltip="The model has not been loaded" />
  ) : (
    ''
  );

  useEffect(() => {
    if (hasLocation) {
      dispatch(change('update_location', 'MODEL_UPLOADED', true));
    }
  }, [hasLocation]);

  const info = (
    <InfoTooltipIcon
      tooltip={
        'After selecting the file, wait for it to unpack, then click "Upload"'
      }
    />
  );

  useEffect(() => {
    if (updatePano && updateMesh) {
      setUpdatePano(false);
    }
  }, [updateMesh]);

  useEffect(() => {
    if (updateMesh && updatePano) {
      setUpdateMesh(false);
    }
  }, [updatePano]);

  const tryParseModel = async file => {
    resetUploadModel();
    ZipFileInput.zipFile = file;
    try {
      if (!generateMesh) {
        const data = await parseModel(
          file,
          locationDescription,
          false,
          (name, percent) => {
            setSubmitButtonLabel(`${percent}%`);
          }
        );
        onModelParse(data);
      } else {
        const reader = new zipjs.ZipReader(new zipjs.BlobReader(file));
        const zip = await reader.getEntries();
        validateArchiveMeshGenerate(zip, file.name);
      }
      setSubmitButtonLabel('Upload');

      return true;
    } catch (error) {
      console.log('Zip processing error:', error);
      createToast('ERROR', error.message.toString(), error.heading);

      return false;
    }
  };

  const handleFieldChange = (fieldName, newValue) => {
    setMeshOptions(prevOptions => ({
      ...prevOptions,
      [fieldName]: newValue,
    }));
  };

  const handleValidationAndSubmit = () => {
    if (isNaN(meshOptions.scan_depth_range)) {
      createToast('ERROR', 'scan_depth_range value is not valid');

      return false;
    }
    if (isNaN(meshOptions.downsampling)) {
      createToast('ERROR', 'downsampling value is not valid');

      return false;
    }
    if (isNaN(meshOptions.voxel_size)) {
      createToast('ERROR', 'voxel_size value is not valid');

      return false;
    }
    if (isNaN(meshOptions.convert_depth)) {
      createToast('ERROR', 'convert_depth value is not valid');

      return false;
    }

    return true;
  };

  const parseViaGenerator = async locationId => {
    setSaveGenMesh(true);
    try {
      const data = await parseModelGenerator(
        locationId,
        locationDescription,
        false,
        (name, percent) => {
          setSubmitButtonLabel(`${percent}%`);
        }
      );
      onModelParse(data);
    } catch (e) {
      console.error(e);
      createToast('ERROR', 'Error occurs, try save again');
      setSaveGenMesh(false);
    }
    setSaveGenMesh(false);
  };

  const onBake = async () => {
    setBakeButtonDisabled(true);
    setBakeButtonLabel('0%');
    const result = await bakeTextures(
      {
        depthThreshold: bakeDepthThreshold,
        useHalfDepth: bakeHalfDepth,
        quality: bakeQuality,
        locationId,
        parentId: locationDescription.PARENT_VARIANT,
      },
      ZipFileInput.zipFile,
      percent => {
        setBakeButtonLabel(`${percent}%`);
      }
    );

    ZipFileInputStorage.newFiles.push(...result);
    setBakeButtonLabel(`The textures are baked`);

    await sendNewFiles();
    setBakeButtonLabel(`Bake the textures`);
    setBakeButtonDisabled(false);
  };

  const sendNewFiles = async () => {
    try {
      const finalZip = await ZipBuilder.build(
        undefined,
        ZipFileInputStorage.newFiles
      );

      ZipFileInputStorage.newFiles = [];

      if (!finalZip) {
        throw new Error('Upload error');
      }

      const fd = new FormData();
      fd.append('file', finalZip);
      fd.append('id', locationId);

      await API.requestWithXHR(API.endpoints.UPLOAD_ONE_FILE, fd, () => {});

      createToast('SUCCESS', 'Dollhouse textures downloaded');
    } catch (e) {
      createToast('ERROR', e.message);
    }
  };

  const sendPanos = async files => {
    setUploading(true);

    const zip = new JSZip();
    const resultZip = new JSZip();
    let panos;

    if (files.item(0).name.endsWith('.zip')) {
      const loadedZip = await zip.loadAsync(files.item(0));
      panos = loadedZip.filter(f => f.match(/equi\/eq_\d+\.jpg/));

      const generateBlob = async file => {
        const blob = await file.async('blob');
        resultZip.file(file.name, blob);
      };

      await Promise.all(panos.map(f => generateBlob(f)));
    } else {
      panos = Array.from(files).filter(f => f.name.match(/eq_\d+\.jpg/));
      panos.forEach(f => {
        resultZip.file('equi/' + f.name, f);
      });
    }

    if (panos.length === 0) {
      setUploading(false);
      createToast('ERROR', 'there are no equi panoramas in the archive');

      return;
    }

    await resultZip.generateAsync({ type: 'blob' }).then(async blob => {
      resultZip.lastModifiedDate = new Date();
      resultZip.name = 'archive.zip';

      const fd = new FormData();
      fd.append('file', blob);
      fd.append('id', locationId);

      await API.request(API.endpoints.UPLOAD_MODEL_FILE, fd);

      createToast('SUCCESS', 'Panoramas have been successfully updated');
    });

    setUploading(false);
  };

  const sendLocation = async file => {
    setUploading(true);

    if (!(await tryParseModel(file))) {
      setUploading(false);

      return;
    }

    const zip = new JSZip();
    const zipLoaded = await zip.loadAsync(file);
    const files = Object.values(zipLoaded.files);

    const gltf = await files.find(f => f.name.endsWith('.gltf'));
    const bin = await files.find(f => f.name.endsWith('.bin'));

    if (!gltf) {
      setUploading(false);
      createToast('ERROR', 'archive should contain .gltf ');

      return;
    }

    if (!bin) {
      setUploading(false);
      createToast('ERROR', 'archive should contain .bin ');

      return;
    }

    const resultZip = new JSZip();

    resultZip.file('location.gltf', await gltf.async('blob'));
    resultZip.file('location.bin', await bin.async('blob'));

    resultZip.generateAsync({ type: 'blob' }).then(async blob => {
      resultZip.lastModifiedDate = new Date();
      resultZip.name = 'archive.zip';

      const fd = new FormData();
      fd.append('file', blob);
      fd.append('id', locationId);

      await API.request(API.endpoints.UPLOAD_MODEL_FILE, fd);

      createToast('SUCCESS', 'The mesh has been successfully updated');
    });
    setUploading(false);
  };

  const handleSubmit = async files => {
    if (updateMesh) {
      await sendLocation(files.item(0));
    } else if (updatePano) {
      await sendPanos(files);
    } else {
      if (!(await tryParseModel(files.item(0)))) {
        return;
      }
      if (generateMesh && !handleValidationAndSubmit()) {
        return;
      }
      onSubmit(files.item(0), generateMesh, meshOptions);
    }
  };

  const onFileSelect = () => {
    setLocationArchiveSelected(true);
  };

  let meshGenerationString = '';

  if (meshGenerationQueuePos) {
    if (meshGenerationQueuePos.isQueued) {
      meshGenerationString = `Position in queue: ${
        meshGenerationQueuePos.queued_locations + 1
      }`;
    } else if (meshGenerationQueuePos.inProgres) {
      meshGenerationString = `Generating model... - ${meshGenerationQueuePos.percent}%`;
    }
  }
  const rangeOptions = [
    { key: 50, value: 50000 },
    { key: 100, value: 100000 },
    { key: 150, value: 150000 },
    { key: 200, value: 200000 },
    { key: 250, value: 250000 },
    { key: 300, value: 300000 },
    { key: 350, value: 350000 },
    { key: 400, value: 400000 },
  ];

  const resolutionOptions = [
    { key: 8192, value: 8192 },
    { key: 4096, value: 4096 },
    { key: 16384, value: 16384 },
  ];

  return (
    <>
      {!instantPano && (
        <GreyPage>
          <Row>
            <Col>
              <FileInput
                multiple
                label={
                  <h5>
                    Model <span className="text-muted">(.zip)</span> {info}{' '}
                    {alert}
                  </h5>
                }
                labelFooter={
                  <div>
                    {!photoTourChecked && hasLocation && (
                      <div>
                        <div className="form-group row">
                          <Form.Label column sm={3}>
                            Baking setting{' '}
                            <InfoTooltipIcon tooltip="Baking setting" />
                          </Form.Label>
                          <div className="col-sm-9">
                            <Button
                              onClick={() =>
                                setShowBakeSettings(!showBakeSettings)
                              }
                              size="sm"
                            >
                              {showBakeSettings ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )}
                            </Button>
                          </div>
                        </div>
                        {showBakeSettings && (
                          <div
                            className="mt-4 mb-4 pt-4 pb-3 pl-4 pr-4 collapse show"
                            style={{ backgroundColor: 'rgb(244, 244, 244)' }}
                          >
                            <FormLabel style={{ marginRight: '1rem' }}>
                              Panorama max penetration modifier when baking
                              <InfoTooltipIcon
                                style={{ marginLeft: '8px' }}
                                tooltip="A modifier for how deeply the projected panorama textures penetrate the geometry when baked. High values can cause blurring of baked textures, while low values can cause loss of information from some panoramas. The default value is 1.0."
                              />
                            </FormLabel>
                            <input
                              type="number"
                              placeholder="1.0"
                              min="0"
                              max="10"
                              step="0.1"
                              defaultValue={bakeDepthThreshold}
                              onChange={e => {
                                console.log(e.target.valueAsNumber);
                                if (!isNaN(e.target.valueAsNumber)) {
                                  setBakeDepthThreshold(e.target.valueAsNumber);
                                }
                              }}
                              disabled={bakeButtonDisabled}
                            />
                            <br />
                            <FormLabel style={{ marginRight: '1rem' }}>
                              Use 0.5x depth map for baking:
                            </FormLabel>
                            <input
                              type="checkbox"
                              defaultChecked={bakeHalfDepth}
                              onChange={e => {
                                setBakeHalfDepth(e.currentTarget.checked);
                              }}
                              disabled={bakeButtonDisabled}
                            />
                            <br />
                            <FormLabel style={{ marginRight: '1rem' }}>
                              Baking quality:
                            </FormLabel>
                            <input
                              type="number"
                              min="0"
                              max="1"
                              step="0.01"
                              defaultValue={bakeQuality}
                              onChange={e => {
                                if (!isNaN(e.target.valueAsNumber)) {
                                  setBakeQuality(e.target.valueAsNumber);
                                }
                              }}
                              disabled={bakeButtonDisabled}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                }
                accept=".zip, .jpg"
                onSubmit={handleSubmit}
                onFileSelect={onFileSelect}
                submitButtonLabel={
                  uploading
                    ? uploadingText || 'Uploading...'
                    : submitButtonLabel
                }
                submitButtonDisabled={uploading || !locationArchiveSelected}
                // errorText={error}
                bakeButtonLabel={bakeButtonLabel}
                bakeButtonDisabled={bakeButtonDisabled}
                bakeButton={!photoTourChecked && hasLocation}
                onBake={onBake}
              />

              {uploading ? <h5>Archive uploading...</h5> : null}
              <div
                style={{
                  display: uploading ? 'flex' : 'none',
                  width: '400px',
                  height: '20px',
                  background: '#f2f2f2',
                  marginBottom: '10px',
                  justifyContent: 'start',
                }}
              >
                <div
                  style={{
                    width: `${uploadingProgress * 100}%`,
                    height: '100%',
                    background: '#007bff',
                    transition: 'width 0.2s ease',
                  }}
                ></div>
              </div>
              {meshGenerationString && (
                <div style={{ marginLeft: '15px' }}>
                  <Row>
                    <RotatingSpan style={{ marginRight: '8px' }}>
                      <VanishIcon color="#222" />
                    </RotatingSpan>
                    {meshGenerationString}
                  </Row>
                </div>
              )}
              {hasLocation && (
                <Row style={{ marginLeft: 0, marginBottom: '10px' }}>
                  <Button
                    style={{ marginRight: '8px', marginLeft: 0 }}
                    size="sm"
                    variant="primary"
                    onClick={() => parseViaGenerator(locationId)}
                  >
                    Save generated mesh
                  </Button>
                  {saveGenMesh && (
                    <RotatingSpan>
                      <VanishIcon color="#222" />
                    </RotatingSpan>
                  )}
                </Row>
              )}
              {!instantPano && (
                <div className="d-flex align-items-center w-40">
                  <span className="mr-4 mb-1">
                    Generate dollhouse
                    <InfoTooltipIcon tooltip="Automatically generate a GLTF model from panoramas using depth maps. The archive should contain panoramas in jpg format and depth maps in EXR format. The archive must have a coords.txt file at the root." />
                  </span>
                  <Form.Check
                    type="switch"
                    id="toggle-gen"
                    onChange={() => setGenerateMesh(prev => !prev)}
                    checked={generateMesh}
                  />
                </div>
              )}
              {!instantPano && generateMesh && (
                <div className="mb-1">
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Scan depth range
                      <InfoTooltipIcon tooltip="Render distance of depth maps." />
                    </Form.Label>
                    <Form.Control
                      style={{ width: '100px' }}
                      type="number"
                      min="0"
                      step="1"
                      value={
                        isNaN(meshOptions.scan_depth_range)
                          ? ''
                          : meshOptions.scan_depth_range
                      }
                      onChange={e =>
                        handleFieldChange(
                          'scan_depth_range',
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Target number of triangles
                      <InfoTooltipIcon tooltip="The number of triangles in the resulting mesh, affect the quality of the mesh." />
                    </Form.Label>
                    <Field
                      name="type"
                      type="text"
                      label=""
                      as="select"
                      span={12}
                      component={renderField}
                      onChange={e =>
                        handleFieldChange(
                          'simplify_target_tris',
                          parseInt(e.target.value)
                        )
                      }
                      value={meshOptions.simplify_target_tris}
                    >
                      {rangeOptions.map(option => (
                        <option
                          key={option.key}
                          value={option.value}
                          selected={
                            meshOptions.simplify_target_tris === option.value
                          }
                        >
                          {option.value}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Convert Depth
                      <InfoTooltipIcon tooltip="Mesh detail level. Higher value - higher quality and size of the mesh" />
                    </Form.Label>
                    <Form.Control
                      style={{ width: '100px' }}
                      type="number"
                      min={8}
                      max={13}
                      step="1"
                      defaultValue={0}
                      value={meshOptions.convert_depth}
                      onChange={e =>
                        handleFieldChange(
                          'convert_depth',
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Voxel Size
                      <InfoTooltipIcon tooltip="Reduces the density of the pointcloud. Higher value - lower quality" />
                    </Form.Label>
                    <Form.Control
                      style={{ width: '100px' }}
                      type="number"
                      defaultValue={0.0}
                      step="0.01"
                      min="0.01"
                      max="0.03"
                      value={meshOptions.voxel_size}
                      onChange={e => {
                        const inputValue = e.target.value;
                        if (!isNaN(inputValue)) {
                          handleFieldChange(
                            'voxel_size',
                            parseFloat(inputValue)
                          );
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Exterior location?
                      <InfoTooltipIcon tooltip="Is the location an exterior? If yes, all holes in the mesh will be filled except for the largest one." />
                    </Form.Label>
                    <Form.Check
                      style={{ width: '100px' }}
                      defaultChecked={meshOptions.is_location_open}
                      onChange={e =>
                        handleFieldChange('is_location_open', e.target.checked)
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Remove small meshes
                      <InfoTooltipIcon tooltip="If enabled, removes all unconnected fragments of the mesh except for the largest one." />
                    </Form.Label>
                    <Form.Check
                      style={{ width: '100px' }}
                      defaultChecked={meshOptions.remove_small_meshes}
                      onChange={e =>
                        handleFieldChange(
                          'remove_small_meshes',
                          e.target.checked
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3}>
                      Texture resolution
                      <InfoTooltipIcon tooltip="texture_resolution" />
                    </Form.Label>
                    <Field
                      type="number"
                      label=""
                      as="select"
                      span={12}
                      component={renderField}
                      onChange={e =>
                        handleFieldChange(
                          'texture_resolution',
                          parseInt(e.target.value)
                        )
                      }
                      value={meshOptions.texture_resolution}
                    >
                      {resolutionOptions.map(option => (
                        <option
                          key={option.key}
                          value={option.value}
                          selected={
                            meshOptions.texture_resolution === option.value
                          }
                        >
                          {option.value}
                        </option>
                      ))}
                    </Field>
                  </Form.Group>
                </div>
              )}
              <div className="d-flex align-items-center w-40">
                <span className="mr-4 mb-1">Panoramas only</span>
                <Form.Check
                  type="switch"
                  id="toggle-1"
                  onChange={() => setUpdatePano(prev => !prev)}
                  checked={updatePano}
                />
              </div>
              {!instantPano && (
                <div className="d-flex align-items-center w-40">
                  <span className="mr-4 mb-1">Mesh only</span>
                  <Form.Check
                    type="switch"
                    id="toggle-2"
                    onChange={() => setUpdateMesh(prev => !prev)}
                    checked={updateMesh}
                  />
                </div>
              )}
            </Col>
          </Row>
        </GreyPage>
      )}
    </>
  );
};

ZipFileInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

ZipFileInput.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: 'update_location',
  enableReinitialize: true,
})(ZipFileInput);
