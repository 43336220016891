import { useCallback, useState } from 'react';
import API from '../../../../shared/utils/API';
import { MOVE_OBJECT_TYPE } from './ContextMenu';

export const useMoveMenuHistory = (initial = []) => {
  const [history, setHistory] = useState([{}, ...initial]);

  const goBack = useCallback(() => {
    setHistory(prev => {
      if (prev.length === 1) {
        return prev;
      }

      prev.splice(0, 1);

      return [...prev];
    });
  }, []);

  const pushHistory = useCallback(
    (name, id) => {
      const currentState = history[0];
      const newState = {};
      if (Object.keys(currentState).length === 0) {
        newState.company = {
          name,
          id,
        };
      } else if (currentState.company) {
        newState.company = currentState.company;
        newState.multilocation = { name, id };
      }
      setHistory(prev => [newState, ...prev]);
    },
    [history]
  );

  const canPush = moveObjectType => {
    const curr = history[0];
    if (moveObjectType === MOVE_OBJECT_TYPE.MULTILOCATION) {
      if (curr.company) {
        return false;
      }
    }
    // else if(moveObjectType === MOVE_OBJECT_TYPE.LOCATION){
    //    if(curr.company){
    //    return false;
    //  }
    // }

    return true;
  };

  return {
    history,
    goBack,
    pushHistory,
    canPush,
  };
};

export const useUpdateLocation = (history, selectedMultilocation) => {
  const canUpdateLocation = () => {
    const currentState = history[0];

    // if(currentState.multilocation){
    //   return false;
    // }
    if (!currentState.company) {
      return false;
    }
    if (!selectedMultilocation.name && !currentState.multilocation) {
      return false;
    }

    return true;
  };

  const updateLocation = async (locationId, description) => {
    const currentState = history[0];

    const data = {
      locationId,
      companyName: currentState.company.name,
      description: `'${JSON.stringify({
        ...description,
        LIST_ID: selectedMultilocation.name
          ? selectedMultilocation.name
          : currentState.multilocation.name,
      })}'`,
    };

    await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
  };

  return { updateLocation, canUpdateLocation };
};

export const useUpdateMultilocation = (history, selectedCompany) => {
  const canUpdateMultilocation = () => {
    const currentState = history[0];

    if (!currentState.company && !selectedCompany.id) {
      return false;
    }

    return true;
  };

  const updateMultilocation = async id => {
    const currentState = history[0];

    const data = {
      id,
      data: {
        companyId:
          currentState.company && currentState.company.id
            ? currentState.company.id
            : selectedCompany.id,
      },
    };
    await API.request(API.endpoints.UPDATE_MULTILOCATION, data);
  };

  return {
    updateMultilocation,
    canUpdateMultilocation,
  };
};
