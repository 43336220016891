import * as actionTypes from '../actions/types';

const initialState = {
  data: null,
  loaded: false,
};

export default function LocationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_LOCATION: {
      return action.payload.location;
    }
    case actionTypes.RESET_LOCATION: {
      return initialState;
    }
    default:
      return state;
  }
}
