import JSZip from 'jszip';
import API from './API';

const UPLOAD_BY_PARTS = false;

const ZipBuilder = {};

export const sendPartZip = async zip => {
  return new Promise(resolve => {
    zip.generateAsync({ type: 'blob' }).then(async blob => {
      zip.lastModifiedDate = new Date();
      zip.name = 'location.zip';

      const fd = new FormData();
      fd.append('file', blob);
      fd.append('id', ZipBuilder.location.data.id);
      await API.request('UPLOAD_MODEL_FILE', fd);

      resolve();
    });
  });
};

const clearLocation = async () => {
  return new Promise(resolve => {
    const zip = new JSZip();

    zip.file('location.gltf', '');

    zip.generateAsync({ type: 'blob' }).then(async blob => {
      blob.lastModifiedDate = new Date();
      blob.name = 'location.zip';

      const fd = new FormData();
      fd.append('file', blob);
      fd.append('id', ZipBuilder.location.data.id);
      await API.request(API.endpoints.UPLOAD_MODEL, fd);

      resolve();
    });
  });
};

// Adds new files to zip archive
ZipBuilder.build = async (zipFile, newFiles = []) => {
  return new Promise(async (resolve, reject) => {
    const zip = zipFile ? await JSZip.loadAsync(zipFile) : new JSZip();

    if (zipFile && zipFile.size > 1e9 && UPLOAD_BY_PARTS) {
      await clearLocation();

      let totalSize = 0;
      const sizeLimit = 1e8;
      let currentPartZip = new JSZip();

      for (const [url, obj] of Object.entries(zip.files)) {
        if (!obj.dir) {
          if (totalSize >= sizeLimit) {
            if (currentPartZip) {
              await sendPartZip(currentPartZip);
            }

            console.log('Creating new zip...');

            totalSize = 0;
            currentPartZip = new JSZip();
          }

          const blob = await zip.file(url).async('blob');
          currentPartZip.file(url, blob);
          console.log('Added ', url);

          totalSize += obj._data.uncompressedSize;
        }
      }

      if (currentPartZip) {
        await sendPartZip(currentPartZip);
      }

      if (newFiles.length > 0) {
        const newFilesZip = new JSZip();

        for (const [fileName, fileData] of newFiles) {
          newFilesZip.file(fileName, fileData);
        }

        await sendPartZip(newFilesZip);
      }

      resolve(null);
    } else {
      for (const [fileName, fileData] of newFiles) {
        zip.file(fileName, fileData);
      }

      zip.generateAsync({ type: 'blob' }).then(blob => {
        blob.lastModifiedDate = new Date();
        blob.name = 'location.zip';
        resolve(blob);
      });
    }
  });
};

ZipBuilder.location = null;

export default ZipBuilder;
