import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';

const SharePopup = ({ show, text, onClose }) => {
  const textarea = createRef();
  useEffect(() => {
    if (show) {
      textarea.current.select();
    }
  }, [show]);

  return (
    <div>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <FormControl
              as="textarea"
              aria-label="With textarea"
              ref={textarea}
              rows={5}
              onFocus={event => event.target.select()}
              defaultValue={text}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SharePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SharePopup;
