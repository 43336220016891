const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required field';
  }
  if (!values.image) {
    errors.image = 'Add a image';
  }

  return errors;
};

export default validate;
