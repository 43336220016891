import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';

const MAGIC_LINK_PUBLIC_API_KEY = 'pk_live_FF936F098A9D0A25';

const createMagic = key => {
  return (
    typeof window !== 'undefined' &&
    new Magic(key, {
      extensions: [new OAuthExtension()],
    })
  );
};

export const magic = createMagic(MAGIC_LINK_PUBLIC_API_KEY);
