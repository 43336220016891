import React, { useState } from 'react';

function LocationItemContext({ name, description }) {
  const [err, setErr] = useState(false);

  return (
    <div className="d-flex align-items-center mt-1">
      {err ? (
        <img
          className="image no-preview"
          src={`${process.env.PUBLIC_URL}/img/noPreview.png`}
        />
      ) : (
        <img
          className="image location-preview"
          src={`${process.env.REACT_APP_MEDIA_URL}/${description.PREVIEW_IMAGE}?t=${performance.timeOrigin}`}
          onError={() => setErr(true)}
        />
      )}
      <div className="text">{name}</div>
    </div>
  );
}

export default LocationItemContext;
