import React from 'react';
import { Row, Form } from 'react-bootstrap';

const Checkbox = ({ input, label, meta: { touched, error }, type, hide }) => (
  <Form.Group as={Row} className={hide ? 'd-none' : ''}>
    <div className="col-sm-9 d-flex align-items-center">
      <Form.Check
        {...input}
        type={type}
        aria-label={label}
        checked={input.value || false}
        onChange={input.onChange}
      />
      {label && <Form.Label className="mb-0 ml-2">{label}</Form.Label>}
    </div>
    {touched && error && <span className="offset-sm-3">{error}</span>}
  </Form.Group>
);

export default Checkbox;
