import React from 'react';
import { Container, Navbar, Nav, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import LoginIcon from 'mdi-react/LoginIcon';
import LogoutIcon from 'mdi-react/LogoutIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';

const Header = ({ auth, setSearchName, searchName, location }) => {
  const history = useHistory();

  return (
    <Navbar bg="dark" variant="dark">
      <Container
        style={{ maxWidth: '1500px' }}
        className="d-flex flex-column flex-sm-row justify-content-between"
      >
        <Container
          style={{ maxWidth: '1020px' }}
          className="d-flex flex-column flex-sm-row justify-content-start"
        >
          <Link to="/">
            <Navbar.Brand className="header__logo d-flex justify-content-center">
              <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="logo" />
            </Navbar.Brand>
          </Link>
          <Nav.Item style={{ color: 'white' }}>
            {location.loaded
              ? location.data &&
                (location.data.description.IS_INTERACTIVE_PLAN
                  ? 'Interactive Floor Plan'
                  : location.data.description.INSTANT_PANO_ONLY
                    ? '2D Tour'
                    : '3D Tour')
              : ''}
          </Nav.Item>
          <Nav.Item className="mr-1 ml-3">
            <ArrowBackIcon
              size="30"
              color="white"
              onClick={() => history.goBack()}
            />
          </Nav.Item>
          <Nav.Item>
            {!window.location.href.match('location') && (
              <ArrowBackIcon
                size="30"
                style={{ transform: 'rotate(180deg)' }}
                color="white"
                onClick={() => history.goForward()}
              />
            )}
          </Nav.Item>
        </Container>
        <Nav>
          {auth && auth.authenticated ? (
            <>
              <Nav.Item className="d-flex align-items-center">
                <Form.Control
                  placeholder="Enter a name to search"
                  type="text"
                  style={{
                    height: 30,
                    minWidth: 200,
                  }}
                  value={searchName}
                  onChange={e => setSearchName(e.target.value)}
                />
                <MagnifyIcon
                  style={{
                    height: 30,
                    color: 'white',
                    margin: 4,
                  }}
                />
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/dashboard">
                  Dashboard
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/furniture">
                  Furniture
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/hotspots">
                  Hotspots
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/icons">
                  Icons
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/logout">
                  <LogoutIcon />
                </Link>
              </Nav.Item>
            </>
          ) : (
            <Nav.Item>
              <Link className="nav-link" to="/login">
                <LoginIcon />
              </Link>
            </Nav.Item>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
