import React, { useEffect } from 'react';
import { logout } from '../../MagicAuth/auth';

const Login = () => {
  useEffect(() => {
    logout().finally(() => {
      window.location.href = window.location.origin;
    });
  }, []);

  return <React.Fragment />;
};

export default Login;
