import { MagicTokenSingleton } from '../../shared/utils/MagicTokenSingleton';
import API from '../../shared/utils/API';
import { magic } from '../../shared/utils/magic';

async function loginCompanyByToken() {
  try {
    // await API.request(API.endpoints.LOGIN_ADMIN, {});
    return API.request(API.endpoints.LOGIN_ADMIN, {}).then(r => r.result);
  } catch (err) {
    console.error(err);

    return false;
  }
}

export async function loginWithSession() {
  const isLoggedIn = await magic.user.isLoggedIn();

  if (!isLoggedIn) {
    return false;
  }

  try {
    await MagicTokenSingleton.I.init(magic);
  } catch (e) {
    return false;
  }

  return loginCompanyByToken();
}

export async function loginWithGoogle() {
  const isLoggedIn = await magic.oauth.getRedirectResult();

  if (!isLoggedIn) {
    return false;
  }

  try {
    await MagicTokenSingleton.I.init(magic);
  } catch (e) {
    return false;
  }

  return loginCompanyByToken();
}

export async function loginWithEmail(email) {
  const token = await magic.auth.loginWithMagicLink({
    email,
    showUI: true,
  });
  await MagicTokenSingleton.I.init(magic, token);

  return loginCompanyByToken();
}

export async function logout() {
  return magic.user.logout();
}
