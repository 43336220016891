import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function SwitchEditorTypeButton({ clickHandler, variant, tooltip, children }) {
  return (
    <div className="mr-2">
      <OverlayTrigger
        placement="top"
        flip={true}
        overlay={<Tooltip id="b-t">{tooltip}</Tooltip>}
      >
        <Button variant={variant} onClick={clickHandler} size="sm">
          {children}
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default SwitchEditorTypeButton;
