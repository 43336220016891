import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

function ToggleButton({ label, onClick, selected }) {
  return (
    <Button
      variant={selected ? 'primary' : 'outline-primary'}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

function ToggleButtonPanel({ items, onSelect, defaultItem }) {
  const [selected, setSelected] = useState(defaultItem);

  useEffect(() => {
    if (selected) {
      onSelect(selected);
    }
  }, [selected]);

  return (
    <>
      {items.map(({ id, ...props }) => (
        <ToggleButton
          key={id}
          onClick={() => setSelected(id)}
          selected={selected === id}
          {...props}
        />
      ))}
    </>
  );
}

export default ToggleButtonPanel;
