import * as actionTypes from '../actions/types';

export default function SearchReducer(state = '', action) {
  switch (action.type) {
    case actionTypes.SET_SEARCH_NAME: {
      return action.name;
    }
    default:
      return state;
  }
}
