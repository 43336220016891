import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Card, Alert } from 'react-bootstrap';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <Form.Group>
    <Form.Label>{label}</Form.Label>
    <Form.Control {...input} type={type} placeholder={label} />
    {touched && error && <span className="text-danger">{error}</span>}
  </Form.Group>
);

const LoginForm = ({ handleSubmit, showError }) => {
  return (
    <div>
      {showError && (
        <Alert variant="danger">Email or password is incorrect</Alert>
      )}
      <Card className="mb-5">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
              label="Email"
              component={renderField}
            />
            <Field
              name="password"
              type="password"
              label="Password"
              component={renderField}
            />
            <Button variant="primary" type="submit" className="w-100">
              Sign In
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'login',
})(LoginForm);
