import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  loadCompanies,
  resetCompanies,
  loadCompaniesBySearch,
  setSearchName,
  setHideEmptyFolders,
  showContextMenu,
} from '../../../../redux/actions';
import Folder from '../Folder';
import EmptyPlus from '../EmptyPlus';
import PlusItemCard from '../PlusItemCard';
import Company from '../Company';
import { useCreateCompany, useDeleteCompany, useRenameCompany } from './hooks';
import ContextMenu from '../ContextMenu';

function CompaniesList({
  companies,
  auth,
  loadCompanies,
  searchName,
  loadCompaniesBySearch,
  setSearchName,
  hideEmptyFolders,
  setHideEmptyFolders,
  showContextMenu,
}) {
  if (!auth.authenticated) {
    return <Redirect to="/" />;
  }

  const load = () => {
    if (searchName !== '') {
      loadCompaniesBySearch(searchName);
    } else {
      loadCompanies();
    }
  };

  const { path } = useRouteMatch();
  const deleteCompany = useDeleteCompany(load);
  const createCompany = useCreateCompany();
  const renameCompany = useRenameCompany();

  useEffect(() => {
    setSearchName('');
  }, [window.location.href]);

  useEffect(() => {
    load();
  }, [searchName]);

  // useEffect(() => {
  //   load();
  //
  //   return () => {
  //     resetCompanies();
  //   };
  // }, []);

  return (
    <>
      <Container>
        <Switch>
          <Route exact path={`${path}`}>
            <ContextMenu />
            <Row>
              <Col>
                <h1 className="mt-3 mb-3">Companies</h1>
              </Col>
              <Col className="d-flex align-items-center mt-1">
                <input
                  type="checkbox"
                  checked={hideEmptyFolders}
                  onClick={() => setHideEmptyFolders(!hideEmptyFolders)}
                  className="mt-1"
                  onChange={() => {}}
                />
                <div className="ml-3">Hide companies without locations</div>
              </Col>
            </Row>
            <Row>
              {companies.value.length > 0 ? (
                <>
                  <PlusItemCard onClick={createCompany} />
                  {companies.value.map((company, index) => (
                    <Folder
                      key={index}
                      name={encodeURIComponent(company.name)}
                      show={hideEmptyFolders ? !company.isEmpty : true}
                      searchName={searchName}
                      showContextMenu={showContextMenu}
                      contextMenuProps={{
                        deleteHandler: () => deleteCompany(company.id),
                        renameHandler: () => renameCompany(company),
                        canShowMove: false,
                      }}
                    />
                  ))}
                </>
              ) : (
                companies.loaded && <EmptyPlus onClick={createCompany} />
              )}
            </Row>
          </Route>
          <Route
            path={`${path === '/' ? '' : path}/:company`}
            component={Company}
          />
        </Switch>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  companies: state.companyList,
  auth: state.auth,
  searchName: state.searchName,
  hideEmptyFolders: state.hideEmptyFolders,
});

const mapDispatchToProps = {
  loadCompanies,
  resetCompanies,
  loadCompaniesBySearch,
  setSearchName,
  setHideEmptyFolders,
  showContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList);
