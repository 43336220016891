import React, { useState, useMemo, useReducer } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col, Button, CardGroup } from 'react-bootstrap';
import DeleteIcon from 'mdi-react/DeleteIcon';

import AlertTooltipIcon from '../../../shared/components/AlertTooltipIcon';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import API from 'shared/utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { loadLocationDetails } from 'redux/actions';

const MapFileInput = ({
  handleSubmit,
  errorText,
  uploading,
  images,
  floors,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const submitButtonDisabled = useMemo(
    () => uploading || !isFileSelected || errorText,
    [uploading, isFileSelected]
  );

  const location = useSelector(state => state.location);
  const dispatch = useDispatch();
  const imgs = useMemo(() => images || {}, [images]);

  const alert = images ? '' : <AlertTooltipIcon tooltip="No image uploaded" />;

  const getFloorImageURL = floor => {
    if (images && images[floor]) {
      return `${process.env.REACT_APP_MEDIA_URL}/${images[floor]}`;
    }

    return '';
  };

  function deleteFloorHandler(floor) {
    return async e => {
      e.stopPropagation();
      const img = images[floor];
      const path = img.split('/');
      const body = { id: location.data.id, file: path[path.length - 1] };
      const l = location.data;
      delete l.description.MAP_IMAGES[floor];

      const data = {
        locationId: l.id,
        name: l.name,
        companyName: l.company_name,
        description: `'${JSON.stringify(l.description)}'`,
      };

      await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
      await API.request(API.endpoints.DELETE_MAP, body);
      dispatch(loadLocationDetails(location.data.id));
    };
  }

  return (
    <Row className="pt-3 pb-3 mb-3 bg-light">
      <Col>
        <Row>
          <Col className="mb-2">
            <h5>
              Minimap <span className="text-muted">(.png)</span> {alert}
            </h5>
          </Col>
          <Col>
            <Button
              onClick={handleSubmit}
              size="sm"
              disabled={submitButtonDisabled}
              style={{ minWidth: '100px' }}
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardGroup>
              {floors.map(floor => (
                <Field
                  key={`${imgs[floor] || `${floor}`}`}
                  name={`${floor}`}
                  label={
                    <div>
                      {floor === 1
                        ? `${floor}-st floor`
                        : floor === 2
                          ? `${floor}-nd floor`
                          : floor === 3
                            ? `${floor}-d floor`
                            : floor > 3
                              ? `${floor}-th floor`
                              : ''}
                      {imgs[floor] && (
                        <DeleteIcon onClick={deleteFloorHandler(floor)} />
                      )}
                    </div>
                  }
                  onChange={() => setIsFileSelected(true)}
                  component={renderDropZoneField}
                  // key={floor}
                  image={getFloorImageURL(floor)}
                  base64
                  accept=".png"
                  style={{ marginRight: '10px' }}
                  maxSize={10}
                />
              ))}
            </CardGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

MapFileInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
};

MapFileInput.defaultProps = {
  uploading: false,
};

export default reduxForm({
  form: 'upload_map',
})(MapFileInput);
