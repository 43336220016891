import * as LocationBaker from 'theview-location-baker';
import { existsOnServer } from './API';

const settings = {
  parentId: -1,
  locationId: -1,
};

export const buildUrl = (id, asset) => {
  return `${process.env.REACT_APP_MEDIA_URL}/locations/${id}/model/${asset}`;
};

const download = async asset => {
  const isExists = await existsOnServer(buildUrl(settings.locationId, asset));
  const url = isExists
    ? buildUrl(settings.locationId, asset)
    : buildUrl(settings.parentId, asset);
  const res = await fetch(url);

  return { url, res };
};

const fetchEquiNames = async locationId => {
  const req = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/getEquiFileNamesFromLocation?locationId=${locationId}`
  );
  const json = await req.json();

  if (json.result) {
    json.result = json.result.filter(
      fileName => fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')
    );
  }

  return json;
};

export default async function bakeTextures(
  { depthThreshold, useHalfDepth, quality, locationId, parentId },
  zipFile,
  callback
) {
  return new Promise(async resolve => {
    const remoteEquiNames = await fetchEquiNames(locationId);
    const panoNames = remoteEquiNames.result
      .filter(
        fileName => fileName.endsWith('.jpg') && fileName.startsWith('eq_')
      )
      .map(fileName =>
        parseInt(fileName.replace('eq_', '').replace('.jpg', ''))
      );
    console.log('Detected equi panos:', panoNames);
    LocationBaker.init(getDecodedPan, panoNames);

    settings.parentId = parentId;
    settings.locationId = locationId;

    const { url, res } = await download('location.gltf');
    LocationBaker.setCache('location.gltf', res.result);
    const bin = await download('location.bin');
    LocationBaker.setCache('location.bin', bin.res.result);

    const textures = await LocationBaker.bake({
      canvas: document.createElement('canvas'),
      modelUrl: url,
      resolution: 2048,
      depthResolution: 2048 * (useHalfDepth ? 0.5 : 1),
      depthThreshold,
      callback,
    });

    const newFiles = [];

    for (const [textureName, texture] of Object.entries(textures)) {
      const colorBlob = await convertImgToBase64(texture, 'data', quality);
      const maskBlob = await convertImgToBase64(texture, 'mask', quality);

      newFiles.push([textureName + '.jpg', colorBlob]);
      newFiles.push([textureName + '_mask.jpg', maskBlob]);
    }

    resolve(newFiles);
  });
}

const getDecodedPan = async panId => {
  return new Promise(async resolve => {
    console.log('Decoding pan #' + panId);

    const { res } = await download(`equi/eq_${panId}.jpg`);
    const data = await res.blob();
    const bitmap = await createImageBitmap(data);

    // if(!data) {
    //   const res =
    //   const res  = await fetch(`${urlGlobal}/equi/eq_${panId + 1}.jpg`);
    //   data = await res.blob();
    //
    // }
    // else {
    //   bitmap = await createImageBitmap(new Blob([new Uint8Array(data).buffer], {type: 'image/jpeg'}));
    // }

    resolve(bitmap);
  });
};

const convertImgToBase64 = (texture, dataPropName, quality) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = texture.height;
    canvas.width = texture.width;

    if (dataPropName === 'mask') {
      canvas.height = Math.max(1, canvas.height / 2);
      canvas.width = Math.max(1, canvas.width / 2);
    }

    const imageData = new ImageData(
      new Uint8ClampedArray(texture[dataPropName]),
      canvas.width,
      canvas.height
    );
    ctx.putImageData(imageData, 0, 0);

    canvas.toBlob(
      blob => {
        resolve(blob);
      },
      'image/jpeg',
      quality
    );
  });
};
