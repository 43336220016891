import * as actionTypes from '../actions/types';

const defaultSettings = {
  showPlayer: false,
  hash: '',
};
const savedSettings = localStorage.getItem('settings');
const initialState = savedSettings
  ? { ...defaultSettings, ...JSON.parse(savedSettings) }
  : defaultSettings;

export default function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SETTINGS: {
      return {
        ...state,
        ...action.payload.settings,
      };
    }
    default:
      return state;
  }
}
