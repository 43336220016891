import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  Button,
  Col,
  Form,
  FormLabel,
  ProgressBar,
  Row,
} from 'react-bootstrap';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';

import FileInput from '../../../shared/components/form/FileInput';
import VanishIcon from 'mdi-react/VanishIcon';
import styled from 'styled-components';
import API from '../../../shared/utils/API';
import JSZip from 'jszip';
import { buildAndSendGLTF } from './GTLFBuilderInput';
import { createToast, loadLocationDetails } from '../../../redux/actions';
import * as zipjs from '@zip.js/zip.js';
import { getEquiFiles, validateArchive2d } from '../../../shared/utils/validate';
import ToggleButtonPanel from '../../../shared/components/ToggleButtonPanel';
import InfoTooltipIcon from '../../../shared/components/InfoTooltipIcon';

const resolutions = [
  { label: '2К Tiles', value: [512, 1024, 2048], id: '2k tiles' },
  { label: '4К Tiles', value: [512, 1024, 2048, 4096], id: '4k tiles' },
];
const tileLoadingStages = ['uploaded', 'previews', 512, 1024, 2048, 4096];
const tileLoadingStagesCompleteLabels = [
  'Equi uploaded',
  'Tiles generated',
  '512 sliced',
  '1024 sliced',
  '2048 sliced',
  '4096 sliced',
];
const tileLoadingStagesProgressLabels = [
  'Equi uploading...',
  'Tiles generating...',
  '512 slicing...',
  '1024 slicing...',
  '2048 slicing...',
  '4096 slicing...',
];
export const CloseIcon = styled.span`
  position: absolute;
  cursor: pointer;
  color: #b6b6b6;

  &:hover {
    color: white;
  }
`;

const RotatingSpan = styled.span`
  transform: rotate(0deg);
  animation: loading 2s linear forwards infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const GreyPage = styled.div`
  padding: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding-bottom: 10px;
`;

const TileInput = ({
  onSubmit,
  onFileSelect,
  onTileSlicingCancel,
  allowUploading,
  uploading,
  tilesUploaded,
  instantPanoOnly,
  furnitureItems,
  uploaded,
  stats = {},
  error,
  dispatch,
  id,
  location,
  resetTilesStatsProgress,
  hasLocation,
}) => {
  const [checked, setChecked] = useState([]);
  const [compress, setCompress] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [lastStats, setLastStats] = useState(null);
  const [sentResolutions, setSentResolutions] = useState(null);
  const [loadingStats, setLoadingStats] = useState(null);
  const [slicingQuality, setSlicingQuality] = useState(85);
  const [useTiles, setUseTiles] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [equiFileNamesServerContains, setEquiFileNamesServerContains] =
    useState([]);

  const allTileStagesCompleted = Object.values(
    loadingStats || { v: false }
  ).every(v => v === true);

  useEffect(() => {
    if (location.loaded) {
      // console.log(location);
      setUseTiles(location.data.description.USE_TILED_CUBEMAPS);
    }
  }, [location.loaded]);

  useEffect(() => {
    if (sentResolutions) {
      const initialState = {};

      for (const res of sentResolutions) {
        initialState[res] = false;
      }

      setLoadingStats(initialState);
    }
  }, [sentResolutions]);

  useEffect(() => {
    if (!compress) return;

    dispatch(change('update_location', 'USE_BASIS', true));
  }, [compress]);

  useEffect(() => {
    if (checked && checked.length) {
      // dispatch(change('update_location', 'USE_TILED_CUBEMAPS', true));
      dispatch(change('update_location', 'USE_4K_TILES', true));
      dispatch(change('update_location', 'PRELOAD_CAMERA_DIRECTION', true));
    }
  }, [checked]);

  useEffect(
    () =>
      setTotalProgress(Number(localStorage.getItem(`cubemap-progress-${id}`))),
    []
  );

  useEffect(() => {
    const savedProgress = Number(
      localStorage.getItem(`cubemap-progress-${id}`)
    );
    if (stats.active && !totalProgress && !savedProgress) {
      setTotalProgress(stats.active);
      localStorage.setItem(`cubemap-progress-${id}`, stats.active.toString());
    }

    if (stats.active > totalProgress && stats.active > savedProgress) {
      localStorage.setItem(`cubemap-progress-${id}`, stats.active.toString());
      setTotalProgress(stats.active);
    }
  }, [stats, totalProgress]);

  useEffect(() => {
    const savedProgress = Number(
      localStorage.getItem(`cubemap-progress-${id}`)
    );
    if (stats.active && savedProgress) setTotalProgress(savedProgress);

    if (stats.active > 0 && !sentResolutions) {
      // tile processing is running, but now there's no way to know what resolutions were requested
      setSentResolutions([]);
    }

    if (stats && stats.active > 0) {
      const currentStats = { ...loadingStats };
      let keyAdded = false;
      let numAdded = false;

      for (const [key, value] of Object.entries(stats)) {
        if (
          value > 0 &&
          (resolutions.indexOf(+key) !== -1 || key === 'previews') &&
          !currentStats.hasOwnProperty(key)
        ) {
          currentStats[+key] = false;
          keyAdded = true;

          if (resolutions.indexOf(+key) !== -1) {
            numAdded = true;
          }
        }
      }

      if (keyAdded) {
        currentStats.uploaded = true;
      }

      if (numAdded) {
        currentStats.previews = true;
      }

      setLoadingStats(currentStats);
    }

    if (loadingStats && stats.active > 0) {
      if (loadingStats.uploaded === false && tilesUploaded === true) {
        setLoadingStats({ ...loadingStats, uploaded: true });
      } else if (loadingStats.previews === false && stats.previews === 0) {
        setLoadingStats({ ...loadingStats, previews: true });
      } else if (
        loadingStats[512] !== undefined &&
        stats[512] === 0 &&
        !loadingStats[512]
      ) {
        setLoadingStats({ ...loadingStats, 512: true });
      } else if (
        loadingStats[1024] !== undefined &&
        stats[1024] === 0 &&
        !loadingStats[1024]
      ) {
        setLoadingStats({ ...loadingStats, 1024: true });
      } else if (
        loadingStats[2048] !== undefined &&
        stats[2048] === 0 &&
        !loadingStats[2048]
      ) {
        setLoadingStats({ ...loadingStats, 2048: true });
      } else if (
        loadingStats[4096] !== undefined &&
        stats[4096] === 0 &&
        !loadingStats[4096]
      ) {
        setLoadingStats({ ...loadingStats, 4096: true });
      }
    }

    // all stages are complete
    if (stats.active === 0 && lastStats && lastStats.active > 0) {
      const newStats = {};

      for (const key of Object.keys(loadingStats)) {
        newStats[key] = true;
      }

      setLoadingStats(newStats);
    }

    if (Object.keys(stats).length > 0) setLastStats({ ...stats });
  }, [stats]);

  useEffect(() => {
    setLoadingStats(null);
  }, [resetTilesStatsProgress]);

  useEffect(() => {
    dispatch(change('update_location', 'USE_EQUIRECTANGULAR', !useTiles));
    dispatch(change('update_location', 'USE_TILED_CUBEMAPS', useTiles));
  }, [useTiles]);

  const addCheckedResolution = id => {
    setSelectedId(id);

    if (id) {
      setChecked(resolutions.find(r => r.id === id).value);
    }
  };

  const setTiles = () => {
    setUseTiles(prev => !prev);
  };

  const updateMipmap = count => {
    const points = location.data.description.LOCATION_POINT_SHORT_INFO;

    const mipmapResolution = { width: 1024, height: 1024 };

    if (points.length < count) {
      for (let i = points.length; i < count; i++) {
        const info = points[i];
        points.push({
          cam: { x: Math.random(), y: 0, z: 0 },
          floor: 1,
          markers: [],
          name: 'Point №' + (i + 1),
          x: 0,
          y: 0,
        });

        location.data.description.LOCATION_POINT_SHORT_INFO = points;
      }
    } else if (points.length > count) {
      location.data.description.LOCATION_POINT_SHORT_INFO = points.slice(
        0,
        count
      );
    }

    const minmapPoints =
      location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO || [];

    if (minmapPoints.length < count) {
      for (let i = minmapPoints.length; i < count; i++) {
        minmapPoints.push({
          cam: { x: Math.random(), y: 0, z: 0 },
          floor: 1,
          markers: [],
          name: 'Point №' + (i + 1),
          x: 0,
          y: 0,
        });
      }

      location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
        minmapPoints;
    } else if (minmapPoints.length > count) {
      location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO =
        minmapPoints.slice(0, count);
    }

    // setPoints(location.data.description.LOCATION_POINT_SHORT_INFO);

    // return location.data.description.LOCATION_POINT_SHORT_INFO
  };

  const updateDescription = async (fields) => {
    try {
      const description = await API.getLocationDescription(id);

      let newDescriprion = {...description, ...fields}

      const data = {
        locationId: id,
        name: location.data.name,
        companyName: location.data.company_name,
        description: `'${JSON.stringify(newDescriprion)}'`,
      };
      await API.request(API.endpoints.UPDATE_LOCATION_FULL, data);
      dispatch(loadLocationDetails(id));
      dispatch(createToast('SUCCESS', 'Location description has been updated'));
    } catch (error) {
      console.error(error);
      dispatch(createToast('ERROR', error.toString()));
    }
  }

  const handleSubmit = async (files, generateGltf) => {
    try {
      let withoutSlicing = instantPanoOnly;
      let isUpdatingPano = false;
      let equiCount = 0;
      if(Array.from(files).length > 1){
        equiCount = files.length;
        isUpdatingPano = equiCount === location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO.length
      } else if (files[0]) {
        const archiveExt = ['zip', '7z', 'rar'];
        const fileExt = files[0].name.split('.')[1];
        const isArchive = archiveExt.includes(fileExt);

        if (instantPanoOnly && isArchive) {
          const reader = new zipjs.ZipReader(new zipjs.BlobReader(files[0]));

          const zip = await reader.getEntries();
          equiCount = getEquiFiles(zip).length;
          if(instantPanoOnly){
            isUpdatingPano = equiCount === location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO.length
          }

          validateArchive2d(zip, files[0].name);
        }
      }

      Array.from(document.querySelectorAll('.btn.btn-primary[type="submit"]'))
        .find(btn => btn.textContent === 'Update settings')
        .click();
      await new Promise(rs => setTimeout(rs, 3000));

      if (checked.length === 0) {
        return;
      }

      localStorage.setItem(`cubemap-progress-${id}`, '0');
      setTotalProgress(0);

      const resolutionsChecked = [...checked];
      setSentResolutions([...resolutionsChecked, 'uploaded', 'previews']);
      setUseTiles(true);

      onSubmit({
        files,
        compress,
        resolutions: resolutionsChecked,
        withoutSlicing,
        sliceOnlyCubemaps: false,
        slicingQuality,
      });

      if (generateGltf && files[0] && !isUpdatingPano) {
        try {
          
          await buildAndSendGLTF(equiCount, () => {});
          updateMipmap(equiCount);
          await updateDescription({
            MODEL_UPLOADED: true
          })
          await updateDescription({
            INSTANT_PANO_LOCATION_POINT_SHORT_INFO: location.data.description.INSTANT_PANO_LOCATION_POINT_SHORT_INFO
              .map(p => ({...p, arrows: []})),
            LOCATION_POINT_SHORT_INFO: location.data.description.LOCATION_POINT_SHORT_INFO.map(p => ({...p, arrows: []})),
          })
          console.log('add model', equiCount)
        } catch (e) {
        }
      }
    } catch (e) {
      dispatch(createToast('ERROR', e.message, e.heading));
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/getEquiFileNamesFromLocation?locationId=${id}`
    )
      .then(r => r.json())
      .then(r => r.result)
      .then(names => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          setEquiFileNamesServerContains(names);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [id, uploaded, tilesUploaded, allTileStagesCompleted]);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    (instantPanoOnly ||
      (!instantPanoOnly &&
        (hasLocation || uploaded || furnitureItems.length > 0))) && (
      <React.Fragment>
        <Row>
          <Col>
            {instantPanoOnly ? (
              <GreyPage>
                <FileInput
                  label={<h5>Panoramas uploading</h5>}
                  accept={'.jpg, .zip'}
                  multiple
                  onSubmit={async files =>
                    await handleSubmit(files, instantPanoOnly)
                  }
                  onFileSelect={onFileSelect}
                  submitButtonLabel={
                    tilesUploaded
                      ? 'Cubes uploaded'
                      : uploading
                        ? 'Uploading...'
                        : 'Upload'
                  }
                  submitButtonDisabled={
                    uploading || !allowUploading
                    // (![...checked].length && !withoutSlicing)
                  }
                  errorText={error}
                  disableInput={!!stats.active || uploading}
                />{' '}
              </GreyPage>
            ) : null}
            <GreyPage>
              <h5>Panoramas editing</h5>
              <Button
                onClick={openModal}
                variant="primary"
                type="submit"
                style={{ margin: '0.5rem 0 0.5rem', marginRight: '10px' }}
                disabled={loadingStats}
              >
                Configure offset/blur
              </Button>
              <InfoTooltipIcon tooltip="Editor for changing panoramas offset and nadir / zenith blur. After setting the required parameters and saving, the panoramas are modified on the server. In case of any changes of parameters it is necessary to re-cut panoramas!" />
              {isOpen && (
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '1000',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                      borderRadius: '30px',
                      width: '85rem',
                      height: '50rem',
                    }}
                  >
                    <CloseIcon
                      style={{
                        position: 'absolute',
                        top: '2.6vh',
                        right: '2.9vw',
                        cursor: 'pointer',
                        fontSize: '35px',
                        fontWeight: 'bold',
                      }}
                      onClick={closeModal}
                    >
                      &times;
                    </CloseIcon>
                    <iframe
                      src={`${process.env.REACT_APP_PLAYER_URL}/eq-editor?locationId=${id}`}
                      // src={`https://localhost:8080/eq-editor?locationId=${id}`}
                      width="100%"
                      height="100%"
                      style={{ border: 'none', borderRadius: '30px' }}
                    ></iframe>
                  </div>
                </div>
              )}
            </GreyPage>
            <GreyPage>
              <h5>Slicing into tiles</h5>
              {loadingStats && (
                <Col className={'mb-2'}>
                  {!allTileStagesCompleted &&
                    loadingStats &&
                    tileLoadingStages
                      .filter(
                        stageName => loadingStats[stageName] !== undefined
                      )
                      .filter(
                        (stageName, i, arr) =>
                          i === 0 || loadingStats[arr[i - 1]] === true
                      )
                      .map(stageName => {
                        const isComplete = loadingStats[stageName] === true;
                        const labelList = isComplete
                          ? tileLoadingStagesCompleteLabels
                          : tileLoadingStagesProgressLabels;
                        const listId = tileLoadingStages.indexOf(stageName);
                        const text = labelList[listId];

                        if (isComplete) {
                          return (
                            <Row key={stageName} className={'pt-1 pb-1'}>
                              <CheckCircleIcon
                                color="#72BB53"
                                style={{ marginRight: '8px' }}
                              />
                              {text}
                            </Row>
                          );
                        }

                        return (
                          <React.Fragment key={stageName}>
                            {listId === 0 ? (
                              <Row className={'pt-1 pb-1'}>
                                <RotatingSpan style={{ marginRight: '8px' }}>
                                  <VanishIcon color="#222" />
                                </RotatingSpan>
                                Equi uploading...
                              </Row>
                            ) : null}

                            {!!stats.active && !stats.beforeLocation ? (
                              <Row className={'pt-1 pb-1'}>
                                <RotatingSpan style={{ marginRight: '8px' }}>
                                  <VanishIcon color="#222" />
                                </RotatingSpan>
                                Tiles/cubemaps generating
                              </Row>
                            ) : null}
                          </React.Fragment>
                        );
                      })}

                  {allTileStagesCompleted &&
                    stats.beforeLocation === 0 &&
                    stats.active === 0 && (
                      <Row className={'pt-1 pb-1'}>
                        <CheckCircleIcon
                          color="#72BB53"
                          style={{ marginRight: '8px' }}
                        />
                        Slicing is complete
                      </Row>
                    )}
                </Col>
              )}
              {stats.active && stats.beforeLocation ? (
                <div>
                  <Col>
                    <Row>
                      {`Another location is currently being sliced. There are still ${stats.beforeLocation} tasks left before your location is sliced`}
                    </Row>
                    <Row className="text-primary d-flex align-items-center">
                      <Button
                        size="sm"
                        variant="danger"
                        style={{ marginBottom: '16px' }}
                        onClick={() => onTileSlicingCancel()}
                      >
                        <CloseCircleIcon />
                      </Button>
                    </Row>
                  </Col>
                </div>
              ) : null}
              {!!stats.active && !stats.beforeLocation && (
                <div>
                  <Col>
                    <Row>
                      {`Slicing is in progress. Tasks left to do: ${stats.active}`}
                    </Row>
                    <Row className="text-primary d-flex align-items-center">
                      <ProgressBar
                        className="w-25"
                        now={100 - (stats.active / totalProgress) * 100}
                      />
                      <Button
                        size="sm"
                        variant="danger"
                        style={{ marginLeft: '16px' }}
                        onClick={() => onTileSlicingCancel()}
                      >
                        <CloseCircleIcon />
                      </Button>
                    </Row>
                  </Col>
                </div>
              )}
              {!stats.active && !uploading && (
                <Row className="mb-2 w-40 mt-2">
                  <Col>
                    <FormLabel style={{ marginRight: '1rem' }}>
                      JPEG quality:
                    </FormLabel>
                    <input
                      type="number"
                      placeholder="JPEG quality"
                      defaultValue={slicingQuality}
                      onChange={e => setSlicingQuality(e.target.valueAsNumber)}
                    />
                    <div className="mt-2">
                      <ToggleButtonPanel
                        items={resolutions}
                        onSelect={addCheckedResolution}
                        defaultItem={selectedId || resolutions[0].id}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {hasLocation && (
                <div className="d-flex align-items-center w-40 mt-2">
                  <span className="mr-4 mb-1">Use tiles</span>
                  <Form.Check
                    type="switch"
                    id="toggle-3"
                    onChange={setTiles}
                    checked={useTiles}
                  />
                </div>
              )}
              {!instantPanoOnly || equiFileNamesServerContains.length > 0 ? (
                <Button
                  onClick={() => handleSubmit([], instantPanoOnly)}
                  variant="primary"
                  type="submit"
                  style={{ margin: '1.5rem 0 0.5rem' }}
                >
                  Slice panoramas
                </Button>
              ) : null}
            </GreyPage>
          </Col>
        </Row>
      </React.Fragment>
    )
  );
};

TileInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  allowUploading: PropTypes.bool,
  uploading: PropTypes.bool,
  uploaded: PropTypes.bool,
  error: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number,
};

TileInput.defaultProps = {
  allowUploading: false,
  uploading: false,
  tilesUploaded: false,
  error: '',
};

export default connect(state => state)(TileInput);
