import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from 'react-bootstrap';

import getBase64 from '../../utils/getBase64';

const FileInput = ({
  onSubmit,
  onFileSelect,
  label,
  labelFooter,
  accept,
  multiple,
  submitButtonDisabled,
  submitButtonLabel,
  errorText,
  base64,
  bakeButton,
  onBake,
  bakeButtonLabel,
  bakeButtonDisabled,
  cubesButtonVisible,
  onCubes,
  cubesButtonLabel,
  cubesButtonDisabled,
  disableInput,
  isInteractiveFloorPlan = false,
  levels,
  uploading,
  selectedLevel,
  setSelectedLevel,
}) => {
  const [levelsLocal, setLevelsLocal] = useState(levels);
  const input = createRef();

  useEffect(() => {
    if (isInteractiveFloorPlan) {
      const tempLevels = [...levels];
      if (levels.length === 0) {
        tempLevels[0] = { number: 0 };
      } else {
        tempLevels.unshift({
          number: parseInt(tempLevels[0].number) + 1,
        });
        tempLevels.push({
          number: parseInt(tempLevels[tempLevels.length - 1].number) - 1,
        });
      }
      setLevelsLocal(tempLevels);
    }
  }, []);

  useEffect(() => {
    if (isInteractiveFloorPlan) {
      const tempLevels = [...levels];
      if (levels.length === 0) {
        tempLevels[0] = { number: 0 };
      } else {
        tempLevels.unshift({
          number: parseInt(tempLevels[0].number) + 1,
        });
        tempLevels.push({
          number: parseInt(tempLevels[tempLevels.length - 1].number) - 1,
        });
      }
      setLevelsLocal(tempLevels);
      setSelectedLevel(0);
    }
  }, [levels, isInteractiveFloorPlan, setSelectedLevel]);

  const changeHandler = async e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];

      if (base64) {
        const data = await getBase64(file);
        const type = file.name.split('.').slice(-1)[0];
        onFileSelect && onFileSelect(data, type);
      } else {
        onFileSelect && onFileSelect(file);
      }
    }
  };

  const submitHandler = async () => {
    const files = input.current.files;
    const file = files[0];
    if (multiple) {
      onSubmit(files, selectedLevel);
    } else if (isInteractiveFloorPlan) {
      onSubmit(file, selectedLevel);
    } else if (base64) {
      const data = await getBase64(file);
      const type = file.name.split('.').slice(-1)[0];
      onSubmit(data, type);
    } else {
      onSubmit(file);
    }
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <FormLabel>{label}</FormLabel>
          {labelFooter}
          <Row>
            <Col>
              <FormControl
                type="file"
                accept={accept}
                onChange={changeHandler}
                ref={input}
                multiple={multiple}
                disabled={disableInput}
              />
              {errorText && <span className="text-danger">{errorText}</span>}
            </Col>
            {isInteractiveFloorPlan && (
              <Col>
                <span>Level: </span>
                <select
                  value={selectedLevel}
                  onChange={e => setSelectedLevel(e.target.value)}
                  disabled={uploading}
                >
                  {levelsLocal.map((localLevel, index) => {
                    return (
                      <option key={index} value={localLevel.number}>
                        {localLevel.number}
                      </option>
                    );
                  })}
                </select>
              </Col>
            )}
            <Col>
              <Button
                onClick={submitHandler}
                size="sm"
                disabled={submitButtonDisabled || errorText}
                style={{ minWidth: '100px' }}
              >
                {submitButtonLabel}
              </Button>
              {bakeButton ? (
                <Button
                  size="sm"
                  disabled={bakeButtonDisabled}
                  style={{ width: '150px', marginLeft: '16px' }}
                  onClick={onBake}
                >
                  {bakeButtonLabel}
                </Button>
              ) : null}
              {cubesButtonVisible ? (
                <Button
                  size="sm"
                  disabled={cubesButtonDisabled}
                  style={{ width: '150px', marginLeft: '16px' }}
                  onClick={onCubes}
                >
                  {cubesButtonLabel}
                </Button>
              ) : null}
              {/* { sendDollhouseTextures ? */}
              {/*  <Button */}
              {/*    size='sm' */}
              {/*    disabled={cubesButtonDisabled} */}
              {/*    style={{ width: '150px', marginLeft: '16px' }} */}
              {/*    onClick={onSendDollhouseTextures} */}
              {/*  > */}
              {/*    Upload textures for dollhouse */}
              {/*  </Button> : null } */}
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};

FileInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func,
  label: PropTypes.node.isRequired,
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  errorText: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  base64: PropTypes.bool,
  disableInput: PropTypes.bool,
};

FileInput.defaultProps = {
  multiple: false,
  submitButtonDisabled: false,
  submitButtonLabel: 'Upload',
  bakeButtonDisabled: false,
  bakeButtonLabel: 'Bake textures',
  errorText: '',
  base64: false,
  disableInput: false,
};

export default FileInput;
