import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Modal, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import renderField from './form/Field';
import renderSelect from './form/Select';
import renderCheckbox from './form/PopupCheckbox';
import renderLabel from './form/PopupLabel';

const Popup = ({
  show,
  onSubmit,
  onClose,
  label = { text: '', color: 'black' },
  submitButton = { text: 'DELETE', variant: 'danger' },
  formData,
  hidePopup,
  handleSubmit,
}) => {
  const close = () => {
    onClose && onClose();
    hidePopup && hidePopup();
  };

  const submit = e => {
    if (e) e.preventDefault();
    handleSubmit(e);
  };

  const ref = useRef();

  const triggerSubmit = () => ref.current.click();
  const renderTypes = {
    select: renderSelect,
    input: renderField,
    checkbox: renderCheckbox,
    label: renderLabel,
  };

  return (
    <div>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title style={{ color: label.color }}>{label.text}</Modal.Title>
        </Modal.Header>
        {formData && (
          <Row className="mb-3 mr-3 ml-3">
            <Col>
              <Form onSubmit={submit}>
                {formData.map(({ type, name, props }) => (
                  <Field
                    {...props}
                    name={name}
                    key={type + name}
                    component={renderTypes[type]}
                  />
                ))}
                <Button
                  variant="primary"
                  type="submit"
                  className="d-none"
                  ref={ref}
                >
                  Update settings
                </Button>
              </Form>
            </Col>
          </Row>
        )}

        <Modal.Footer className="border-0">
          <Button onClick={close} variant="secondary">
            Close
          </Button>
          <Button
            onClick={formData ? triggerSubmit : onSubmit}
            variant={submitButton.variant}
          >
            {submitButton.text}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'popup_form',
})(Popup);
