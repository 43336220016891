const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api`;

export const loadCompaniesContextMenu = () => {
  return fetch(`${endpoint}/getAllCompanies`).then(response => response.json());
};

export const loadCompanyDataContextMenu = company => {
  return fetch(
    `${endpoint}/getCompanyData?companyName=${encodeURIComponent(company)}`
  ).then(response => response.json());
};
