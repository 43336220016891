import React from 'react';
import PropTypes from 'prop-types';
import AlertIcon from 'mdi-react/AlertIcon';

import WithTooltip from './WithTooltip';

const AlertTooltipIcon = ({ tooltip }) => {
  return (
    <WithTooltip placement="top" content={tooltip}>
      <AlertIcon color="#ffc107" />
    </WithTooltip>
  );
};

AlertTooltipIcon.propTypes = {
  tooltip: PropTypes.node.isRequired,
};

export default AlertTooltipIcon;
