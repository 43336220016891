export default function generateGLTF(zip, idsArray) {
  let ids = idsArray;

  if (!idsArray) {
    const sidesURLs = zip
      .filter(f => f.filename.startsWith('cube/'))
      .map(f => f.filename)
      .filter(f => f !== 'cube/' && f.includes('.jpg'));

    const panIds = new Set();

    for (const url of sidesURLs) {
      panIds.add(parseInt(url.replace('cube/', '')));
    }

    ids = Array.from(panIds).sort(function (a, b) {
      return a - b;
    });
  }

  let spheresString = '';
  let z = 4;
  let k = 1;
  for (let i = 0; i < ids.length; i++) {
    const x = k * 2;
    if (x >= 95) {
      z = z + 3;
      k = 1;
    }

    spheresString += `{
              "mesh" : 0,
              "name" : "Sphere${ids[i]}",
              "translation" : [${x}, 0.0, ${z}]
          },`;
    k++;
  }

  if (spheresString.length > 0) {
    spheresString = spheresString.slice(0, -1);
  }

  const gltfString = `{
    "asset" : {
        "generator" : "Khronos glTF Blender I/O v1.3.48",
        "version" : "2.0"
    },
    "scene" : 0,
    "scenes" : [
        {
            "name" : "Scene",
            "nodes" : [${new Array(ids.length)
              .fill(1)
              .map((_, i) => i)
              .join(',')}]
        }
    ],
    "nodes" : [${spheresString}],
    "meshes" : [
        {
            "name" : "Sphere01",
            "primitives" : [
                {
                    "attributes" : {
                        "POSITION" : 0
                    },
                    "indices" : 1
                }
            ]
        }
    ],
    "accessors" : [
        {
            "bufferView" : 0,
            "componentType" : 5126,
            "count" : 24,
            "max" : [
                0.19999997317790985,
                0.19999997317790985,
                0.19999997317790985
            ],
            "min" : [
                -0.19999997317790985,
                -0.19999997317790985,
                -0.19999997317790985
            ],
            "type" : "VEC3"
        },
        {
            "bufferView" : 1,
            "componentType" : 5123,
            "count" : 24,
            "type" : "SCALAR"
        }
    ],
    "bufferViews" : [
        {
            "buffer" : 0,
            "byteLength" : 288,
            "byteOffset" : 0
        },
        {
            "buffer" : 0,
            "byteLength" : 48,
            "byteOffset" : 288
        }
    ],
    "buffers" : [
        {
            "byteLength" : 336,
            "uri" : "data:application/octet-stream;base64,AAAAAMvMTD4AAACAQ7KHs/EwFrLLzEy+y8xMvvEwFrJheJozAAAAAMvMTD4AAACAy8xMvvEwFrJheJozmRZ0M/EwFrLLzEw+AAAAAMvMTD4AAACAmRZ0M/EwFrLLzEw+y8xMPvEwFrKdBMCzAAAAAMvMTD4AAACAy8xMPvEwFrKdBMCzQ7KHs/EwFrLLzEy+AAAAAMvMTL4AAACAy8xMvvEwFrJheJozQ7KHs/EwFrLLzEy+AAAAAMvMTL4AAACAmRZ0M/EwFrLLzEw+y8xMvvEwFrJheJozAAAAAMvMTL4AAACAy8xMPvEwFrKdBMCzmRZ0M/EwFrLLzEw+AAAAAMvMTL4AAACAQ7KHs/EwFrLLzEy+y8xMPvEwFrKdBMCzAAABAAIAAwAEAAUABgAHAAgACQAKAAsADAANAA4ADwAQABEAEgATABQAFQAWABcA"
        }
    ]
}
  `;

  // zip.file("location.gltf", gltfString);
  // zip.file("location.bin", "\n");

  return [
    ['location.gltf', gltfString],
    ['location.bin', '\n'],
  ];
}
