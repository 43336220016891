import React from 'react';
import { Col, Row } from 'react-bootstrap';

function ContextFolder({ pushHistory, name, setSelected, selected }) {
  return (
    <Col>
      <Row
        style={{ backgroundColor: selected ? '#818283' : 'transparent' }}
        onDoubleClick={pushHistory}
        onClick={setSelected}
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/folder.png`}
          className="image"
          alt="folder"
        />
        <div
          className={`d-flex justify-content-end align-items-center ${selected ? 'text-selected' : 'text'}`}
        >
          {name}
        </div>
      </Row>
    </Col>
  );
}

export default ContextFolder;
