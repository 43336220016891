import PropTypes from 'prop-types';

export const types = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const toast = PropTypes.shape({
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(types).map(key => types[key])).isRequired,
}).isRequired;
