import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';

import ContextFolder from './ContextFolder';
import LocationItemContext from './LocationItemContext';
import { loadCompaniesContextMenu, loadCompanyDataContextMenu } from './utils';
import {
  useMoveMenuHistory,
  useUpdateLocation,
  useUpdateMultilocation,
} from './hooks';
import { MOVE_OBJECT_TYPE } from './ContextMenu';
import {
  createToast,
  hideContextMenu,
  loadLocationsOfCompany,
} from '../../../../redux/actions';

const isEmptyHistory = state => Object.keys(state).length === 0;

function MoveMenu({
  context,
  hideContextMenu,
  createToast,
  loadLocationsOfCompany,
}) {
  const [folders, setFolders] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState('');

  const { history, goBack, pushHistory, canPush } = useMoveMenuHistory();
  const { updateLocation, canUpdateLocation } = useUpdateLocation(
    history,
    selected
  );
  const { updateMultilocation, canUpdateMultilocation } =
    useUpdateMultilocation(history, selected);
  const { company } = useParams();
  const companyDecoded = decodeURIComponent(company);

  const getValidator = type => {
    if (type === MOVE_OBJECT_TYPE.LOCATION) {
      return canUpdateLocation;
    } else if (type === MOVE_OBJECT_TYPE.MULTILOCATION) {
      return canUpdateMultilocation;
    }
  };

  const pushHistoryHandler = (name, id) => {
    if (!canPush(context.moveObjectType)) {
      return;
    }
    setSelected({});
    setSearch('');
    pushHistory(name, id);
  };

  const goBackHandler = () => {
    setSelected({});
    goBack();
  };

  const getTooltip = state => {
    if (isEmptyHistory(state)) {
      return 'Select a company folder';
    } else if (state.company && !state.multilocation) {
      return 'Select a multilocation folder';
    }

    return null;
  };

  useEffect(() => {
    const load = async () => {
      const currentState = history[0];
      if (isEmptyHistory(currentState)) {
        const data = await loadCompaniesContextMenu();
        setFolders(data.result);
      } else if (currentState.company) {
        const data = await loadCompanyDataContextMenu(
          currentState.company.name
        );
        setFolders(
          currentState.multilocation ? [] : data.result.multilocations
        );
        setLocations(
          currentState.multilocation
            ? data.result.multilocations.find(
                ml => (ml.id = currentState.multilocation.id)
              ).locations
            : data.result.locations
        );
      }
    };

    load();
  }, [history]);

  const applyHandler = async () => {
    try {
      if (context.moveObjectType === MOVE_OBJECT_TYPE.LOCATION) {
        await updateLocation(context.id, context.description);
      } else if (context.moveObjectType === MOVE_OBJECT_TYPE.MULTILOCATION) {
        await updateMultilocation(context.id);
      }
      loadLocationsOfCompany(companyDecoded);
      createToast('SUCCESS', 'Multilocation has been moved successfully');
    } catch (e) {
      createToast('ERROR', e.message);
    } finally {
      hideContextMenu();
    }
  };

  return (
    <div className="move-menu d-flex flex-wrap ">
      <div className="w-100">
        <div className="d-flex mb-2 justify-content-between">
          {history.length > 1 && (
            <ArrowBackIcon
              className=""
              color="#818283"
              onClick={goBackHandler}
            />
          )}
          {getTooltip(history[0]) && (
            <div className="text ml-auto">{getTooltip(history[0])}</div>
          )}
        </div>
        <Form.Control
          autoFocus
          className="w-100 mb-1 search"
          placeholder="Type to filter..."
          onChange={e => setSearch(e.target.value.toLowerCase())}
          value={search}
        />
        <div className="scrollable mb-2 mt-2">
          {folders
            .filter(item =>
              item.name.toLowerCase().match(new RegExp(`${search}`))
            )
            .map(item => (
              <ContextFolder
                key={item.id}
                pushHistory={() => pushHistoryHandler(item.name, item.id)}
                name={item.name}
                selected={item.id === selected.id}
                setSelected={() =>
                  setSelected({ id: item.id, name: item.name })
                }
              />
            ))}
          {locations
            .filter(item =>
              item.name.toLowerCase().match(new RegExp(`${search}`))
            )
            .map(location => (
              <LocationItemContext
                key={location.id}
                name={location.name}
                description={location.description}
              />
            ))}
        </div>
      </div>
      <div className="align-self-end d-flex w-100 justify-content-end">
        {getValidator(context.moveObjectType)() && (
          <Button
            onClick={applyHandler}
            variant={
              getValidator(context.moveObjectType)() ? 'primary' : 'light'
            }
            // disabled={!getValidator(context.moveObjectType)()}
          >
            Move
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  hideContextMenu,
  createToast,
  loadLocationsOfCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveMenu);
