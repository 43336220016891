import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';

const CustomSelectFormControl = ({
  input,
  label,
  meta: { touched, error },
  type,
  options,
  initialValue,
}) => (
  <Form.Group as={Row}>
    {label && <Col sm={3}>{label}</Col>}
    <Col sm={9}>
      <Form.Control
        {...input}
        type={type}
        aria-label={label}
        as={'select'}
        defaultValue={initialValue}
      >
        {options.map(({ name, value }, index) => (
          <option value={value} key={`${name}${index}`}>
            {name}
          </option>
        ))}
      </Form.Control>
      {touched && error && <span className="text-danger">{error}</span>}
    </Col>
  </Form.Group>
);

export default CustomSelectFormControl;