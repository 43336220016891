import React, { useState, useEffect } from 'react';

const MarzipanoErrorsPopup = ({ locationId }) => {
  const [show, setShow] = useState(false);

  const [errorsList, setErrorsList] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TILEGEN_URL}/jobs/errors/${locationId}`)
      .then(r => r.json())
      .then(setErrorsList);
    const interval = setInterval(
      () =>
        fetch(`${process.env.REACT_APP_TILEGEN_URL}/jobs/errors/${locationId}`)
          .then(r => r.json())
          .then(setErrorsList),
      5000
    );

    return () => clearInterval(interval);
  }, []);

  const clearLocationSliceErrors = () => {
    fetch(
      `${process.env.REACT_APP_TILEGEN_URL}/jobs/clear-errors/${locationId}`
    )
      .then(() => setErrorsList([]))
      .catch(e => alert(e));
  };

  return errorsList.length > 0 ? (
    <>
      <button
        onClick={() => setShow(x => !x)}
        className="btn btn-primary"
        style={{ position: 'fixed', top: '50px', left: '30px' }}
      >
        {!show ? 'Expand' : 'Collapse'} slicing error window
      </button>

      <div
        style={{
          display: show ? 'block' : 'none',
          width: '400px',
          height: '500px',
          overflow: 'hidden',
          position: 'fixed',
          left: '30px',
          top: '90px',
          background: '#ccc',
          padding: '10px 0',
        }}
      >
        <h6 style={{ padding: '2px 10px' }}>Slicing errors</h6>

        <div
          style={{
            height: 'calc(100% - 70px)',
            overflow: 'auto',
            padding: '0px 10px',
          }}
        >
          {errorsList.map((task, idx) => {
            return (
              <div key={idx} style={{ padding: '10px 0' }}>
                <div style={{ fontWeight: 'bold' }}>#{idx + 1}</div>
                <div>{task.files.join(', ')}</div>
                <div>Resolutions: {task.resolutions}</div>
                <div
                  style={{
                    fontWeight: 'bold',
                    lineHeight: 1,
                    fontSize: '14px',
                  }}
                >
                  Incorrect aspect ratio of the panorama
                </div>
                ;
              </div>
            );
          })}
        </div>
        <div style={{ height: '40px', padding: '5px' }}>
          <button
            onClick={clearLocationSliceErrors}
            className="btn btn-secondary"
          >
            Clear the error list
          </button>
        </div>
      </div>
    </>
  ) : null;
};

export default MarzipanoErrorsPopup;
