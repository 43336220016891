import React from 'react';
import { Col, Form } from 'react-bootstrap';

export default ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
  span,
  offset,
  children,
  as,
}) => (
  <Col sm={{ span: span || 4, offset: offset || 0 }}>
    <Form.Control
      {...input}
      type={type}
      placeholder={placeholder || label}
      aria-label={placeholder || label}
      as={as}
    >
      {children}
    </Form.Control>
    {touched && error && <span className="text-danger">{error}</span>}
  </Col>
);
