import * as actions from '../actions/types';

export default function ToastsReducer(state = [], action) {
  switch (action.type) {
    case actions.CREATE_TOAST:
      return [...state, { ...action.payload, id: Date.now() }];
    case actions.DELETE_TOAST:
      return state.filter(notification => notification.id !== action.payload);
    default:
      return state;
  }
}
