const TEN_MINUTES = 10 * 60 * 1000;
const TOKEN_LIFESPAN_MS = 630 * 1000;

const ERR_NOT_INITIALIZED = 'MagicTokenSingleton is not initialized';
const ERR_ALREADY_INITIALIZED = 'MagicTokenSingleton is already initialized';

export class MagicTokenSingleton {
  static I = new MagicTokenSingleton();

  constructor() {
    this._magic = null;
    this._token = '';
  }

  getToken() {
    if (!this._token) {
      throw new Error(ERR_NOT_INITIALIZED);
    }

    return this._token;
  }

  async init(magic, initialToken = '') {
    if (this._magic) {
      throw new Error(ERR_ALREADY_INITIALIZED);
    }

    this._magic = magic;

    if (initialToken) {
      this._token = initialToken;
    } else {
      await this._fetchToken();
    }

    setInterval(async () => {
      try {
        await this._fetchToken();
      } catch (e) {
        console.error(e);
      }
    }, TEN_MINUTES);
  }

  _fetchToken = async () => {
    this._token = await this._magic.user.getIdToken({
      lifespan: Date.now() + TOKEN_LIFESPAN_MS,
    });
  };
}
