import * as actionTypes from '../actions/types';

export default function LocationHotspotReducer(state = [], action) {
  switch (action.type) {
    case actionTypes.LOAD_LOCATION_HOTSPOTS: {
      return action.payload.hotspot;
    }
    default:
      return state;
  }
}
