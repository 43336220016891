import { useContext, useState, useEffect } from 'react';
import { ReactReduxContext } from 'react-redux';
import { useHistory } from 'react-router-dom';

import API from '../../../../shared/utils/API';
import {
  createToast,
  hidePopup,
  loadLocationsOfCompany,
  showPopup,
} from '../../../../redux/actions';
import {
  nameRegex,
  subscriptionIdRegex,
  quickBooksRecurringTransactionIdRegex,
} from '../../../AddLocation/components/validate';
import {
  ErrorMessages,
  SuccessMessages,
  WarningMessages,
} from '../../../../constants';

export const useDeleteMultilocation = company => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return id =>
    dispatch(
      showPopup({
        onSubmit: async () => {
          try {
            await API.request(API.endpoints.DELETE_MULTILOCATION, {
              multiTourId: id,
            });
            dispatch(loadLocationsOfCompany(company));
            dispatch(createToast('SUCCESS', SuccessMessages.PROPERTY_DELETING));
          } catch (e) {
            dispatch(
              createToast('ERROR', ErrorMessages.STH_WENT_WRONG + e.message)
            );
            console.log(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: WarningMessages.PROPERTY_DELETING,
          color: 'red',
        },
      })
    );
};

export const useCreateMultilocation = (companies, companyData, company) => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;
  const history = useHistory();
  const [hostingFeeType, setHostingFeeType] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);

  const formData = () =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            await API.request(API.endpoints.CREATE_MULTILOCATION, {
              companyId: companies.value.find(c => c.name === company).id || -1,
              multilocationName: values.multilocationName,
              subscriptionId: values.subscriptionId,
              quickBooksRecurringTransactionId:
                values.quickBooksRecurringTransactionId,
              isPhotographyTour: values.isPhotographyTour ? 1 : 0,
            });
            history.push(
              window.location.pathname +
                `/${encodeURIComponent(values.multilocationName)}`
            );
          } catch (e) {
            dispatch(
              createToast('ERROR', ErrorMessages.STH_WENT_WRONG + e.message)
            );
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter property name',
          color: 'black',
        },
        submitButton: {
          text: 'Create',
          variant: 'success',
        },
        enableReinitialize: true,
        initialValues: {
          multilocationName: '',
          subscriptionId: '',
          quickBooksRecurringTransactionId: '',
          isPhotographyTour: false,
        },
        validate: data => {
          const errors = {};
          if (nameRegex.test(data.multilocationName)) {
            errors.multilocationName = ErrorMessages.FORBIDDEN_CHARACTERS;
          }
          if (!subscriptionIdRegex.test(data.subscriptionId)) {
            errors.subscriptionId = ErrorMessages.WRONG_SUBSCRIPTION_ID_FORMAT;
          }
          if (
            !quickBooksRecurringTransactionIdRegex.test(
              data.quickBooksRecurringTransactionId
            )
          ) {
            errors.quickBooksRecurringTransactionId =
              ErrorMessages.WRONG_QUICK_BOOKS_INVOICE_ID_FORMAT;
          }
          if (data.subscriptionId && data.quickBooksRecurringTransactionId) {
            errors.subscriptionId =
              'You cannot save values to both hosting fee options';
            errors.quickBooksRecurringTransactionId =
              'You cannot save values to both hosting fee options';
            if (data.hostingFeeType === 'NotChosen') {
              errors.hostingFeeType =
                'You cannot save values to both hosting fee options';
            }
          }
          if (!data.multilocationName.trim()) {
            errors.multilocationName = ErrorMessages.REQUIRED_FIELD;
          }
          if (
            companyData.multilocations.find(
              m => m.name === data.multilocationName
            )
          ) {
            errors.multilocationName = ErrorMessages.PROPERTY_EXISTS;
          }

          return errors;
        },
        formData: [
          {
            type: 'input',
            name: 'multilocationName',
            props: {
              placeholder: 'Property name',
              type: 'text',
              onChangeValidate: true,
            },
          },
          {
            type: 'select',
            name: 'hostingFeeType',
            props: {
              options: [
                {
                  name: 'Choose hosting fee type (optional)',
                  value: 'NotChosen',
                },
                { name: 'Stripe', value: 'Stripe' },
                { name: 'QuickBooks', value: 'QuickBooks' },
              ],
              value: hostingFeeType,
              onChange: event => setHostingFeeType(event.target.value),
            },
          },
          ...(hostingFeeType === 'Stripe' || hostingFeeType === 'QuickBooks'
            ? [
                {
                  type: 'input',
                  name:
                    hostingFeeType === 'Stripe'
                      ? 'subscriptionId'
                      : 'quickBooksRecurringTransactionId',
                  props: {
                    placeholder:
                      hostingFeeType === 'Stripe'
                        ? 'Stripe Subscription ID'
                        : 'QuickBooks Recurring Transaction ID',
                    type: 'text',
                    onChangeValidate: true,
                  },
                },
              ]
            : []),
          {
            type: 'checkbox',
            name: 'isPhotographyTour',
            props: {
              label: 'Photography tour',
              onChangeValidate: true,
            },
          },
        ],
      })
    );

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      formData();
    }
  }, [hostingFeeType]);

  return () => formData();
};

export const useRenameMultilocation = company => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = store.dispatch;

  return multilocation =>
    dispatch(
      showPopup({
        onSubmit: async values => {
          try {
            const data = {
              id: multilocation.id,
              data: {
                name: values.name,
                subscriptionId: values.subscriptionId,
                quickBooksRecurringTransactionId:
                  values.quickBooksRecurringTransactionId,
                isPhotographyTour: values.isPhotographyTour ? 1 : 0,
              },
            };
            await API.request(API.endpoints.UPDATE_MULTILOCATION, data);
            dispatch(
              createToast('SUCCESS', SuccessMessages.MULTILOCATION_RENAMING)
            );
            dispatch(loadLocationsOfCompany(company));
          } catch (e) {
            dispatch(createToast('ERROR', e.toString()));
            console.error(e);
          } finally {
            dispatch(hidePopup());
          }
        },
        label: {
          text: 'Please enter new name',
          color: 'black',
        },
        submitButton: {
          text: 'Save',
          variant: 'success',
        },
        enableReinitialize: true,
        initialValues: {
          name: multilocation.name ? multilocation.name : '',
          subscriptionId: multilocation.subscription_id
            ? multilocation.subscription_id
            : '',
          quickBooksRecurringTransactionId:
            multilocation.qb_recurring_transaction_id
              ? multilocation.qb_recurring_transaction_id
              : '',
          isPhotographyTour: multilocation.is_photography_tour,
        },
        validate: data => {
          const errors = {};
          if (nameRegex.test(data.name)) {
            errors.name = ErrorMessages.FORBIDDEN_CHARACTERS;
          }
          if (!data.name.trim()) {
            errors.name = ErrorMessages.REQUIRED_FIELD;
          }
          if (!subscriptionIdRegex.test(data.subscriptionId)) {
            errors.subscriptionId = ErrorMessages.WRONG_SUBSCRIPTION_ID_FORMAT;
          }
          if (
            !quickBooksRecurringTransactionIdRegex.test(
              data.quickBooksRecurringTransactionId
            )
          ) {
            errors.quickBooksRecurringTransactionId =
              ErrorMessages.WRONG_QUICK_BOOKS_INVOICE_ID_FORMAT;
          }
          if (data.subscriptionId && data.quickBooksRecurringTransactionId) {
            errors.quickBooksRecurringTransactionId =
              'You cannot save values to both hosting fee options';
          }

          return errors;
        },
        formData: [
          {
            type: 'input',
            name: 'name',
            props: {
              placeholder: 'Multilocation name',
              type: 'text',
              onChangeValidate: true,
            },
          },
          {
            type: 'label',
            props: {
              text: 'Update hosting fee type',
            },
          },
          {
            type: 'input',
            name: 'subscriptionId',
            props: {
              placeholder: 'Stripe Subscription ID (optional)',
              type: 'text',
              onChangeValidate: true,
            },
          },
          {
            type: 'label',
            props: {
              text: 'or',
            },
          },
          {
            type: 'input',
            name: 'quickBooksRecurringTransactionId',
            props: {
              placeholder: 'QB Recurring Transaction ID (optional)',
              type: 'text',
              onChangeValidate: true,
            },
          },
          {
            type: 'checkbox',
            name: 'isPhotographyTour',
            props: {
              label: 'Photography tour',
              onChangeValidate: true,
            },
          },
        ],
      })
    );
};
